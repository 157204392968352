import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate'
import { useLocation, useParams } from 'react-router-dom';


export default function DeleteOCS(props) {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();
    const handleDelete = async () => {
        setLoading(true)
        try {
            const response = await axiosPrivate.delete(`/oc`, {data: {selected: props.removables, obra_id: props.obra } } )
            props.success(true, `Se eliminaron las OC: ${props.removables.map(x => x).join('\n')}`)
            props.refresh(response.data)
        } catch (error) {
            setError(error.response.data.message)
        }
        setOpen(!open)
        setLoading(false);
    }
    
    return (
        <>
            <button onClick={() => setOpen(!open)} 
                className="disabled:bg-red-300 transition-all ease-in-out hover:scale-105 bg-red-600 hover:bg-red-500 hover:duration-300 p-4 px-6  text-white rounded-md"
                disabled={props.removables.length === 0}
                >
                    <FontAwesomeIcon icon={faTrash} className="w-6 h-6" />
            </button>


            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 ">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="w-1/4 transform overflow-hidden rounded-lg bg-white dark:bg-slate-700 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="dark:text-slate-100 text-lg font-medium leading-6 text-gray-900">
                                                Eliminar
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-slate-100">
                                                    Esta seguro? Se eliminará el registro de la base de datos
                                                    y no se podrá recuperar.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-center mt-6 m-4  gap-12">
                                    
                                        <button
                                            type="button"
                                            className=" inline-flex justify-center p-4 mt-6 bg-gray-600 rounded-md text-white hover:bg-gray-500 hover:duration-300"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancelar
                                        </button>
                                        
                                            
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center p-4 mt-6 bg-red-600 rounded-md text-white hover:bg-red-500 hover:duration-300 "
                                                    onClick={() => handleDelete()}
                                                >
                                                    {
                                                        loading ?
                                                        <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                    :
                                                    'Eliminar' 
                                                    }
                                                </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
