import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'

function AddOC(props) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)



    const [item, setItem] = useState({
        descripcion: '',
        posicion: 0,
        precio_unitario: 0,
        cantidad: 0,
        total: 0
    })

    const [oc, setOC] = useState({
        obra: props.obra._id,
        nro: '',
        items: [],
        facturado: 0
    })

    const handleForm = (e) => {
        e.preventDefault();
        setOC({ ...oc, items: [...oc.items, item] })
        setItem({
            descripcion: '',
            posicion: 0,
            precio_unitario: 0,
            cantidad: 0,
            total: 0
        })
    }

    const handleDeleteItem = (e) => {
        const newItems = [...oc.items]
        newItems.splice(e, 1)
        setOC({ ...oc, items: newItems })
    }


    const handleSubmit = async (e) => {
        setLoading(true)
        try {
            const response = await axiosPrivate.post('oc', oc)
            if(response.status === 200) {
                props.success(true, `OC ${response.data.nro} añadida!`)
                props.refresh(response.data)
                setErr(null)
                setOpen(!open)
            }

        } catch (error) {
            setLoading(false);
            setErr(error.response.data.message)
            throw new Error(`Error creating client: ${error.message}`)
        }
        setLoading(false);
    }



    return (
        <>
            <div className='flex flex-row justify-end p-4'>
                <button onClick={() => {
                    setItem({
                        descripcion: '',
                        posicion: 0,
                        precio_unitario: 0,
                        cantidad: 0,
                        total: 0
                    })
                    setOC({
                        obra: props.obra._id,
                        nro: '',
                        items: [],
                        facturado: 0
                    })
                    setLoading(false);
                    setErr('')
                    setOpen(true)
                }
                } className='p-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 hover:duration-300 transition-all ease-in-out hover:scale-105'>
                    Añadir
                </button>
            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="self-center" onClose={() => {
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-50 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="self-center dark:bg-slate-700 bg-gray-100 h-full overflow-hidden  shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <div className='flex flex-row justify-center'>
                                        <FontAwesomeIcon icon={faFileInvoice} className="self-center left-0 fixed pl-4 h-6 w-6 dark:text-slate-100" />
                                        <Dialog.Title className="m-4 text-center font-medium dark:text-slate-100">Añadir Orden de compra</Dialog.Title>
                                        <button onClick={() => setOpen(false)} className='p-2 px-4 right-0 fixed bg-red-600 hover:bg-red-500 hover:duration-200 text-white font-medium'>X</button>
                                    </div>
                                    <div className='m-4 overflow-y-scroll h-full  pb-48'>
                                        <div className='flex flex-col gap-2'>
                                            {err &&
                                                <div className="relative group mb-6">
                                                    <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                </div>
                                            }
                                        </div>

                                        <div className='flex flex-col gap-2'>
                                            <label className='text-left text-sm font-light dark:text-slate-100' htmlFor='nombre'>Obra</label>
                                            <div className='flex flex-row gap-4'>
                                                <input value={props?.obra?.TAREA} disabled className='dark:shadow-none dark:border-none dark:bg-slate-600 dark:text-slate-100 shadow-md shadow-gray-300 w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='text' />
                                                <input value={props?.obra?.SITIO?.map(({ SITIO }) => SITIO).join('-')} disabled className='dark:border-none dark:shadow-none dark:bg-slate-600 dark:text-slate-100 shadow-md shadow-gray-300 w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='text' />
                                                <input value={props?.obra?.PROYECTO?.name} disabled className='dark:border-none dark:bg-slate-600 first-letter dark:shadow-none dark:text-slate-100 shadow-md shadow-gray-300 w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='text' />
                                            </div>
                                        </div>
                                        <div className='flex flex-col gap-1 w-fit'>
                                            <label className=' mt-4 text-left text-sm font-light dark:border-none dark:text-slate-100'>Número</label>
                                            <input type="number" required onChange={(e) => {
                                                setErr('')
                                                setOC({ ...oc, nro: e.target.value })
                                            }} className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md shadow-gray-300 font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-gray-blue focus:outline-none' />
                                        </div>
                                        <div className="flex flex-row gap-12">
                                            <div className='flex flex-col gap-2'>
                                                <form  className="w-full mt-4" onSubmit={(e) => handleForm(e)}>
                                                    <div className='text-left mt-2 '>
                                                        <button disabled={(!item.descripcion.length > 0) || (!item.precio_unitario > 0) || (!item.cantidad > 0)} type="submit" className=' disabled:bg-blue-300 p-4 rounded-md bg-blue-600 hover:bg-blue-500 hover:duration-200 text-white text-xs w-fit font-medium'>Añadir item</button>
                                                    </div>

                                                    <div className='flex flex-col gap-2'>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Descripción</label>
                                                            <input
                                                                value={item.descripcion}
                                                                onChange={(e) => setItem({ ...item, descripcion: e.target.value })}
                                                                className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='text' />
                                                        </div>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Posición</label>
                                                            <input
                                                                value={item.posicion}
                                                                onChange={(e) => setItem({ ...item, posicion: e.target.value })}
                                                                className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='number' />
                                                        </div>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Precio Unitario</label>
                                                            <input
                                                                value={item.precio_unitario}
                                                                onChange={(e) => setItem({ ...item, precio_unitario: e.target.value, total: e.target.value * item.cantidad })}
                                                                className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' step='0.01' type='number' />
                                                        </div>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Cantidad</label>
                                                            <input
                                                                value={item.cantidad}
                                                                onChange={(e) => setItem({ ...item, cantidad: e.target.value, total: e.target.value * item.precio_unitario })}
                                                                className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='number' />
                                                        </div>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Precio total</label>
                                                            <input
                                                                disabled
                                                                value={item.total.toFixed(2)}
                                                                className='dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='number' />
                                                        </div>


                                                    </div>
                                                </form>

                                                
                                            </div>
                                            {
                                                oc.items.length > 0 &&
                                                <div className=" ">
                                                    <table className="">
                                                        <thead>
                                                            <tr className=''>
                                                                <th colSpan={2} className="px-16 text-gray-500 border dark:text-slate-100 dark:border-slate-600">Descripción</th>
                                                                <th className="px-4 text-gray-500 whitespace-nowrap border dark:text-slate-100 dark:border-slate-600">Precio unitario</th>
                                                                <th className="px-4 text-gray-500 border dark:text-slate-100 dark:border-slate-600">Cantidad</th>
                                                                <th className="px-2 text-gray-500 border dark:text-slate-100 dark:border-slate-600">Subtotal</th>
                                                                <th className="px-2 text-gray-500 border dark:text-slate-100 dark:border-slate-600">Eliminar</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody >
                                                            {
                                                                oc.items.map((i, index) =>

                                                                    <tr key={index} className='bg-gray-100 font-extralight dark:bg-slate-700'>
                                                                        <td colSpan={2} className="text-center border dark:text-slate-100 dark:border-slate-600">{i.descripcion}</td>
                                                                        <td className="text-center border dark:text-slate-100 dark:border-slate-600">$ {i.precio_unitario.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                                        <td className="text-center border dark:text-slate-100 dark:border-slate-600">{i.cantidad}</td>
                                                                        <td className="text-center border dark:text-slate-100 dark:border-slate-600">$ {i.total}</td>
                                                                        <td className="text-center border dark:text-slate-100 dark:border-slate-600  p-2"><button onClick={() => handleDeleteItem(index)} className=' p-1 px-3 border dark:border-none bg-red-600 hover:bg-red-500 hover:duration-200 rounded-lg shadow-md text-white'>X</button></td>
                                                                    </tr>
                                                                )}
                                                                <tr>
                                                                    <td className='text-left border pl-2 font-normal  dark:text-slate-100 dark:border-slate-600' colSpan={6}>Subtotal: $ {oc.items.reduce((acc, item) => (item.precio_unitario * item.cantidad + acc), 0).toLocaleString('es-AR', {minimumFractionDigits:2})}</td>
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                        </div>
                                        
                                    </div>
                                    <div className='w-full bottom-0 fixed'>
                                                    <button onClick={() => handleSubmit()} disabled={(oc.nro === '') || (oc.items.length === 0)} className='dark:border-nonedisabled:bg-gray-300 w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-600 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' >
                                                        {
                                                            loading ?
                                                                <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                </svg>
                                                                :
                                                                'Añadir'
                                                        }
                                                    </button>
                                                </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        </>
    )
}

export default AddOC;