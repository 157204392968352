import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import LoadingSpinner from '../../../../LoadingSpinner';
import AddFactura from './AddFactura';
import ViewFacturas from './ViewFacturas';


const ViewOC = () => {



  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingSent, setLoadingSent] = useState(false);
  const [oc, setOC] = useState({})
  const axiosPrivate = useAxiosPrivate();
  const [isEditable, setIsEditable] = useState(false);
  const total = oc?.items?.reduce((count, item) => item.total + count, 0)
  const [facturado, setFacturado] = useState(0)
  const [error, setError] = useState('')

  const getOC = async () => {
    setLoading(true)
    try {
      const response = await axiosPrivate.get(`oc/${id}`)
      setOC(response.data)
    } catch (error) {
      throw new Error(Error)
    }
    setLoading(false);
  }


  useEffect(() => {
    getOC();
  }, [])

  const handleInputChange = (e) => {
    setError('')
    setFacturado(e.target.value)
  }

  const handleIsEditable = () => {
    setIsEditable(!isEditable)
    setError('')
    setFacturado(0)
  }

  const handleItemsChange = (e, i) => {
    const items = oc.items
    const target = e.target.name
    if (target === 'nro') {
      oc.nro = e.target.value
      setOC(oc)
    } else {
      items[i] = { ...items[i], [target]: e.target.value }
      items[i].total = items[i].precio_unitario * items[i].cantidad
      setOC({ ...oc, items: items })
    }
  }

  const handleUpdate = async () => {
    setLoadingSent(true);
    const NuevoFacturado = (facturado) / 100;
    try {
      const response = await axiosPrivate.put(`oc/${id}`, { ...oc, facturado: NuevoFacturado })
      setOC(response.data);
    } catch (error) {
      setError(error.response.data.message)
    }
    setLoadingSent(false)
    setIsEditable(false)
    setFacturado(0)
  };




  return (
    loading
      ?
      <div className='grid grid-cols-12 h-full'>
        <div className='col-start-7 self-center'>
          <LoadingSpinner />
        </div>
      </div>
      :
      <div className='flex flex-col p-6 gap-8'>
        <div className='flex flex-row justify-between gap-12 font-medium'>
          {
            isEditable ?
              <div className='flex p-4 border-4 rounded-xl bg-green-700 dark:border-none w-fit'>
                <p className='p-2 text-white dark:text-slate-100'>Orden de compra Nº</p>
                <input
                  type="number"
                  name='nro'
                  defaultValue={oc.nro}
                  className='bg-gray-200 focus:outline-none shadow-md text-gray-700 dark:bg-slate-800 dark:text-slate-100 font-normal pl-2 rounded-xl'
                  onChange={(e) => handleItemsChange(e)}
                />
              </div>

              :
              <p className='rounded-md bg-green-700 shadow-md font-medium text-md p-2 self-center bg-green px-4 text-white dark:text-slate-100'>Orden de compra Nº{oc.nro}</p>
          }
          <AddFactura error={(e) => setError(e)} oc={oc} refresh={(e) => setOC(e) } />
        </div>
        <div className='p-4 rounded-md shadow-md'>
          <div hidden={!error} className='row-start-1 col-start-1 col-end-2 h-fit w-fit rounded-md shadow-md text-start p-4 bg-red-500 text-white'>
            {error ? <p>{error}</p> : null}
          </div>

          <div className='flex flex-col gap-4 mt-4'>

            <div className='flex gap-4'>
              <label className='rounded-md w-fit text-left text-sm p-4 px-7 bg-gray-600 dark:text-slate-100 dark:bg-slate-700 text-white font-medium'>Items</label>
              <button onClick={() => handleIsEditable()} className={`hover:bg-yellow-500 bg-yellow-600 p-4   hover:duration-300 rounded-md px-6`}> <FontAwesomeIcon className='text-white text-xl' icon={faEdit} /></button>
            </div>


            <table>
              <thead>
                <tr>
                  <th className='font-light border dark:border-slate-600 dark:text-slate-100'>Descripción</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Posición</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Precio unitario</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Cantidad</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Total</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Facturado</th>
                  <th className='px-4 font-light border dark:border-slate-600 dark:text-slate-100'>Facturas</th>
                </tr>
              </thead>
              <tbody>
                {
                  oc?.items?.map((item, i) =>
                    <tr className=''>
                      <td className='border dark:border-slate-600 dark:text-slate-100 font-extralight px-4'>
                        {
                          isEditable ?
                            <>
                              <input
                                className='pl-2 border border-gray-400 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 w-full'
                                type="text"
                                name='descripcion'
                                defaultValue={item.descripcion}
                                onChange={(e) => handleItemsChange(e, i)}
                              />
                            </>
                            :

                            item.descripcion


                        }
                      </td>
                      <td className='border font-extralight dark:text-slate-100 dark:border-slate-600  px-4'>
                        {
                          isEditable ?
                            <>
                              <input
                                className='pl-2 border border-gray-400 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100  w-full'
                                type="number"
                                name='posicion'
                                defaultValue={item.posicion}
                                onChange={(e) => handleItemsChange(e, i)}
                              />
                            </>
                            :

                            item.posicion


                        }
                      </td>
                      <td className='border font-extralight dark:text-slate-100 dark:border-slate-600  px-4'>
                        {
                          isEditable ?
                            <>
                              <div className='relative'>
                                <span className='absolute pl-2 dark:text-slate-100'>$</span>
                                <input
                                  className='pl-6 border border-gray-400 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 w-full'
                                  type="number"
                                  name='precio_unitario'
                                  value={item.precio_unitario}
                                  onChange={(e) => handleItemsChange(e, i)}
                                />
                              </div>

                            </>
                            :
                            <>
                              $ {item.precio_unitario.toLocaleString('es-AR', { minimumFractionDigits: 2 })}
                            </>
                        }

                      </td>
                      <td className='border font-extralight dark:text-slate-100 dark:border-slate-600  px-4'>
                        {
                          isEditable ?
                            <>
                              <input
                                className='pl-2 border border-gray-400 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 w-full'
                                type="number"
                                name='cantidad'
                                min={1}
                                value={item.cantidad}
                                onChange={(e) => handleItemsChange(e, i)}
                              />
                            </>
                            :

                            item.cantidad


                        }
                      </td>
                      <td className='border font-extralight dark:border-slate-600 dark:text-slate-100 px-4'>

                        $ {item.total.toLocaleString('es-AR', { minimumFractionDigits: 2 })}

                      </td>
                      <td className='border font-extralight dark:border-slate-600  px-4 p-2 justify-center'>

                        <div className="w-full h-fit bg-gray-400 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 group relative inline-block">
                          {

                            item.facturas.length > 0 ?
                              <>
                                <div className=" bg-blue-600 text-xs font-bold text-white text-center p-2 leading-none" style={{ width: `${item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100}%` }}> {(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100)}%</div>
                                <span className={`invisible dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-500 text-white p-2 rounded-md 
                                absolute top-full mt-2 z-50 whitespace-nowrap ${(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100)}
                              `}>{`$ ${(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * item.total).toFixed(2)}`}
                                </span>
                              </>
                              :
                              <>
                                <div className=" bg-blue-600 text-xs   text-white text-center p-2 leading-none font-bold" style={{ width: `0` }}> {0}%</div>
                                <span className={`invisible group-hover:visible opacity-0 dark:border-slate-600 dark:bg-slate-700 dark:text-slate-100 group-hover:opacity-100 transition bg-blue-500 text-white p-2 rounded-md 
                                absolute top-full mt-2 z-50 whitespace-nowrap ${0}
                              `}>{`$ ${0}`}
                                </span>
                              </>
                          }
                        </div>

                      </td>
                      <td className='border dark:border-slate-600  font-extralight px-4 p-2 justify-center flex gap-2'>
                        <ViewFacturas facturas={item}/>
                      </td>
                    </tr>
                  )

                }
              </tbody>
            </table>
          </div>
          <div className='mt-16 col-end-3 flex flex-col gap-4'>
            <label className='rounded-md w-fit text-left text-sm p-4 bg-gray-600 dark:bg-slate-700 dark:text-slate-100 text-white font-medium'>Facturado</label>
            <div className="w-full h-fit bg-gray-400 dark:bg-slate-600 group relative inline-block">
              <div className=" bg-blue-600 text-xs font-light text-white text-center p-2 leading-none rounded-" style={{ width: `${oc.facturado * 100}%` }}> {(oc.facturado * 100)}%</div>
              <span className={`invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-500 text-white p-2 rounded-md 
                               absolute top-full mt-2 z-50 whitespace-nowrap ${oc.facturado}
                `}>{`$ ${(oc.facturado * total).toFixed(2)}`}
              </span>
            </div>

            <button onClick={() => handleUpdate()} hidden={!isEditable} className='self-end rounded-md p-2 px-6 bg-blue-600 hover:bg-blue-500 hover:duration-300 text-white text-center'>
              {
                loadingSent ?

                  <div role="status " className='px-5'>
                    <svg className="inline w-6 h-6 text-white animate-spin  fill-gray-200" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span className="sr-only">Loading...</span>
                  </div>
                  :
                  <>
                    Guardar
                  </>
              }

            </button>
          </div>
        </div>
      </div>

  )
}

export default ViewOC