import React from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'


const InvoiceAdded = (props) => {
   

    return (
        <>
            <AnimatePresence>
                {props.success.state && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ x: 0, opacity: 1}}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0}}
                    className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-md font-medium text-green-800">Cliente {props.success.data} añadido!</p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    onClick={() => {
                                        props.setSuccess(!props.success.state)
                                    }
                                    }
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </motion.div>
                )}
            </AnimatePresence>

        </>
    )
}

export default InvoiceAdded