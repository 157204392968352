import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faBoxesPacking } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const Stock = () => {
  return (
    <div className='h-screen sm:h-100 w-full'>
      <div className='sm:flex-col md:flex md:flex-nowrap pt-2 sm:gap-24 md:gap-12 m-24 mt-12'>
        <Link to={'electrico'}>
          <div className='bg-white dark:bg-slate-700 dark:text-slate-100 rounded-lg shadow-lg transition text-gray-600 duration-300 ease-in-out hover:cursor-pointer hover:bg-gray-800 hover:font-extrabold hover:duration-300 hover:scale-105 hover:text-white'>
            <div className='flex flex-col'>
                <p className='self-center p-2 font-light text-2xl'>Stock eléctrico</p>
                <FontAwesomeIcon icon={faBolt} className="p-48  h-16 w-16 text-center self-center"/>
              <div></div>
            </div>
          </div>
        </Link>
        <div className='bg-white dark:bg-slate-700 dark:text-slate-100 rounded-lg shadow-lg transition text-gray-600 duration-300 ease-in-out hover:cursor-pointer hover:bg-gray-800 hover:font-extrabold hover:duration-300 hover:scale-105 hover:text-white'>
          <div className='flex flex-col'>
              <p className='self-center p-2 font-light text-2xl'>Stock celdas</p>
              <FontAwesomeIcon icon={faBoxesPacking} className="p-48  h-16 w-16 text-center self-center"/>
            <div></div>
          </div>
        </div>
      </div>
    </div>)
}

export default Stock