import React from 'react'

const OC = (props) => {

    return (
        <>
            <td className='font-light dark:text-slate-100 pl-4'>{props.oc.nro}</td>
            <td>
                <p className='mt-2 dark:text-slate-100 font-light text-sm self-start'>{props.oc.obra?.CLIENTE.nombre}</p>
            </td>
            <td>
                <p className='mt-2 dark:text-slate-100 font-light text-sm self-start'>{props.oc.obra?.SITIO ? props.oc.obra?.SITIO.map(SITIO => SITIO.SITIO).join('-') : 'No existe sitio para esta obra'}</p>
            </td>
            <td>
                <p className='mt-2 dark:text-slate-100 font-light text-sm self-start'>{props.oc.obra?.TAREA}</p>
            </td>
            <td className='mt-4'>
                <div className="h-fit bg-slate-600 rounded-md">
                    <div className={
                        `${(props.oc.facturado * 100) < 100 && (props.oc.facturado * 100) > 0  && 'bg-yellow-600'}
                         ${(props.oc.facturado * 100) === 100 && 'bg-green-600'}
                         ${(props.oc.facturado * 100) === 0 && 'bg-red-600'}  
                    
                        text-slate-100 font-light text-sm text-left p-2 leading-none rounded-md`
                        } 
                        style={{ width: `${props.oc.facturado * 100}%` }}> 
                        {props.oc.facturado * 100}%
                    </div>
                </div>
            </td>
            <td className='whitespace-nowrap font-light text-left pl-12 text-sm dark:text-slate-100'>$ {props.oc.items.reduce((acc, item) => item.total + acc, 0).toLocaleString('es-ar', { minimumFractionDigits: 2 })}</td>

        </>
    )
}

export default OC;