import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate'
import { useParams } from 'react-router-dom';

function AddFactura(props) {
    const [step, setStep] = useState(1);
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [items, setItems] = useState()
    const [checkeds, setCheckeds] = useState([])
    const [hasZeroInput, setHasZeroInput] = useState(true)
    const [isReady, setIsReady] = useState(false)
    const [nroFactura, setNroFactura] = useState('')



    const handleNextStep = () => {
        setStep(step + 1);
        setErr('')
    };

    const handlePrevStep = () => {
        setItems([])
        setStep(step - 1);
        setErr('')
    };

    const changeChecked = (e, item, index) => {
        if (e.target.checked) {
            setCheckeds([...checkeds, item._id])
        } else {
            const index = checkeds.indexOf(item._id)
            item.factura = { nro: '', porcentaje: 0, path: '' }

            if (index > -1) {
                checkeds.splice(index, 1)
            }
            setCheckeds([...checkeds])
        }
        if (checkeds.length === 0) {
            setHasZeroInput(true)
        } else if (items.map(i => i?.factura?.porcentaje === '')) {
            setHasZeroInput(true)
        } else if (items.map(i => i?.factura?.porcentaje !== 0)) {
            setHasZeroInput(true)
        }
    }



    const handleChange = (e, index) => {
        if (e.target.value > 0 && e.target.value <= 100) {
            setHasZeroInput(false)
        } else {
            setHasZeroInput(true)
        }
        const newItems = [...props.oc.items];
        newItems[index].factura = { nro: '', porcentaje: e.target.value }
        if (parseFloat(newItems[index].factura.porcentaje) + parseFloat(props.oc.items[index].facturas.reduce((a, b) => a + b.porcentaje, 0)) > 100) {
            setHasZeroInput(true)
        } else {
            setHasZeroInput(false)
            setItems(newItems)
        }

    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)



            try {
                let itemsToEdit = [...items]
                itemsToEdit.forEach(item => {
                    if (nroFactura !== '' && item?.factura?.porcentaje > 0 && item?.factura?.porcentaje <= 100) {
                        item.factura.nro = nroFactura
                        item.facturas.push(item.factura)
                    }
                })

                const { data } = await axiosPrivate.put(`oc/${props.oc._id}`, { items: itemsToEdit })
                setLoading(false)
                props.refresh(data)
                setIsReady(false)
                props.oc.items.forEach(i => i.factura = {})
                setItems([])
                setNroFactura('')
                setCheckeds([])
                setHasZeroInput(true)
                setStep(1) 
                setErr(false);
                setOpen(false)
                setLoading(false)
        } catch (error) {
            setLoading(false)
            setErr(error.response.data)
        }
}



return (
    <>
        <button
            onClick={() => setOpen(true)}
            className='bg-blue-600 hover:bg-blue-500 hover:duration-300 text-white p-2 rounded-md transition-all ease-linear'>
            Añadir facturas
        </button>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className=" fixed z-10 self-center" onClose={() => {
                setIsReady(false)
                props.oc.items.forEach(i => i.factura = {})
                setItems([])
                setCheckeds([])
                setHasZeroInput(true)
                setStep(1)
                setErr(false);
                setOpen(false)
                setLoading(false)
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0">

                    <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="w-6/12 fixed h-4/5 p-4 min-h-max self-center dark:bg-slate-700 bg-white rounded-lg overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <Dialog.Title className="m-4 text-center font-bold dark:text-slate-100 text-xl">Añadir Factura</Dialog.Title>
                                <div className="border-b border-gray-300 dark:border-slate-600 mb-8 pb-4">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-center">
                                            <div className="flex items-center justify-center h-8 w-8 rounded-full bg-blue-500 dark:text-slate-100 text-white mr-2">
                                                {step}
                                            </div>
                                            <h2 className="text-xl font-semibold dark:text-slate-100">
                                                Paso {step}:
                                                {step === 1 && ' Indique el porcentaje facturado'}
                                                {step === 2 && ' Indique el número de factura'}
                                            </h2>
                                        </div>
                                        <div className="text-gray-600 dark:text-slate-100">{step} de 2</div>
                                    </div>
                                </div>
                                {
                                    step === 1 &&
                                    <div className='flex flex-col '>
                                        {
                                            props?.oc?.items?.map((item, i) =>
                                                <div key={item.descripcion} className='flex m-1 bg-gray-200 dark:bg-slate-600 rounded-md items-center'>
                                                    <div>
                                                        <input checked={checkeds.includes(item._id)} onClick={(e) => changeChecked(e, item, i)} id={`inputCheckBox${i}`} type="checkbox" className='m-2' />
                                                    </div>
                                                    <div className='ml-4 w-5/6 text-left'>
                                                        <p className='text-sm text-black dark:text-slate-100'>{item.descripcion}</p>
                                                    </div>
                                                    {

                                                        item.facturas.length > 0 ?
                                                            <>
                                                                <div className='text-left w-1/4 h-fit bg-gray-400 dark:bg-slate-800  group relative inline-block'>
                                                                    <div className=" bg-blue-600 text-xs font-bold text-white text-center p-2 leading-none" style={{ width: `${item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100}%` }}> {(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100)}%</div>
                                                                    <span className={`invisible group-hover:visible opacity-0  group-hover:opacity-100 transition bg-blue-500 text-white p-2 rounded-md 
                                                                            absolute top-full mt-2 z-50 whitespace-nowrap ${(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * 100)}
                                                                        `}>{`$ ${(item?.facturas.reduce((acc, factura) => acc + factura.porcentaje / 100, 0) * item.total).toFixed(2)}`}
                                                                    </span>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='text-left  w-1/4 h-fit bg-gray-400 dark:bg-slate-800 group relative inline-block'>
                                                                    <div className="w-2/3 bg-blue-600 text-xs  text-white text-center p-2 leading-none font-bold" style={{ width: `0` }}> {0}%</div>
                                                                    <span className={`invisible group-hover:visible opacity-0 group-hover:opacity-100 transition bg-blue-500 text-white p-2 rounded-md 
                                                                            absolute top-full mt-2 z-50 whitespace-nowrap ${0}
                                                                        `}>{`$ ${0}`}
                                                                    </span>
                                                                </div>
                                                            </>
                                                    }
                                                    <div className='w-2/3 relative'>
                                                        <span className='absolute mt-4 ml-6 dark:text-slate-100'>%</span>
                                                        <input value={item?.factura?.porcentaje} disabled={!checkeds.includes(item._id)} onChange={(e) => handleChange(e, i)} type="number" defaultValue={0} min={0} max={100} className='dark:border-none dark:bg-slate-800 dark:text-slate-100 rounded-md m-4 pl-8 px-2 border outline-none' />
                                                    </div>

                                                </div>
                                            )
                                        }
                                        <button hidden={hasZeroInput} onClick={handleNextStep} className='m-4 p-4 px-10 bg-blue-500 hover:bg-blue-400 transition-all ease-linear text-white w-fit self-end rounded-md'>Siguiente</button>
                                    </div>
                                }
                                {
                                    step === 2 &&
                                    <>
                                        <form className='mt-6' id="form-submit" action="uploadFile" encType='multipart/form-data' method='post' onSubmit={(e) => handleSubmit(e)}>
                                            <div className='flex flex-col gap-2'>
                                                {err &&
                                                    <div className="relative group mb-6">
                                                        <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className='flex flex-col'>
                                                <div className='flex flex-col gap-4'>
                                                    <label className='text-left dark:text-slate-100'>Número de factura</label>
                                                    <input hidden={hasZeroInput} onChange={(e) => setNroFactura(e.target.value)}
                                                        type="number" className='dark:text-slate-100 dark:bg-slate-800 dark:border-none w-1/2 outline-none border rounded-md p-2 bg-gray-100'
                                                    />
                                                </div>
                                                <button hidden={hasZeroInput} onClick={handlePrevStep} className=' fixed bottom-0 m-4 p-4 px-10 bg-red-500 hover:bg-red-400 transition-all ease-linear text-white w-fit rounded-md'>Atrás</button>
                                                <button type='submit' disabled={(!nroFactura)} className='fixed bottom-0 disabled:bg-blue-200 m-4 p-4 px-10 bg-blue-500 hover:bg-blue-400 transition-all ease-linear text-white w-fit self-end rounded-md'>
                                                    {
                                                        loading ?
                                                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            :
                                                            'Añadir'
                                                    }
                                                </button>
                                            </div>
                                        </form>

                                    </>
                                }

                                {/* <div>
                                        <form className="w-full mt-6" onSubmit={(e) => handleSubmit(e)}>
                                            <div className='ml-4 pr-40'>
                                                <div className='flex flex-col gap-2'>
                                                    {err &&
                                                        <div className="relative group mb-6">
                                                            <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='flex flex-col gap-2 mt-4'>
                                                    <label className='text-left text-sm font-bold' htmlFor='nroFactura'>Item</label>
                                                   
                                                    <input type="text" disabled
                                                        value={props.item.descripcion}
                                                        className='rounded-md  outline-none  pl-2 py-2 w-full bg-gray-200 border-2 border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' name='nroFactura'  />
                                                </div>

                                                <div className='flex flex-col gap-2 mt-4'>
                                                    <label className='text-left text-sm font-bold' htmlFor='nroFactura'>Número</label>
                                                   
                                                    <input type="number" required onChange={(e) => {
                                                        setErr('')
                                                        setFactura({...factura, nro: e.target.value })
                                                        
                                                    }} className='rounded-md  outline-none  pl-2 py-2 w-full border-2 border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' name='nroFactura'  />
                                                </div>
                                                <div className='flex flex-col gap-1 relative'>
                                                    <label htmlFor='porcentaje' className='mt-4 text-left text-sm font-bold'>Porcentaje</label>
                                                    <span className='absolute mt-12 ml-2 font-bold'>%</span>
                                                    <input min="0" max="100" required onChange={(e) => {
                                                        setErr('')
                                                        setFactura({...factura, porcentaje: e.target.value })
                                                    }} className='rounded-md outline-none py-2 pl-8  border-2 border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='number' name='porcentaje'  />
                                                </div>
                                            </div>
                                            <div className='flex justify-end mt-16'>
                                                <button className=' w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-600 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' type='submit'>
                                                    {
                                                        loading ?
                                                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            :
                                                            'Añadir'
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div> */}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </>
)
}

export default AddFactura;