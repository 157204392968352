import {Fragment, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate'
import {faEye, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

function ViewFacturas(props) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false);

    const handleView = async (path) => {
        const encodedPath = encodeURIComponent(path)
        try {
            const {data} = await axiosPrivate.get(`/file/${encodedPath}`, {responseType: 'blob'});
            const blob = new Blob([data], {type: "application/pdf"});
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        } catch (error) {
            throw new Error(`Error: ${error} `);
        }
    }

    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className='bg-gray-600 hover:bg-gray-500 hover:duration-300 text-white p-2 px-4 rounded-md transition-all ease-linear'>
                <FontAwesomeIcon icon={faEye}/>
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className=" fixed z-10 self-center" onClose={() => {
                    setOpen(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity"/>
                    </Transition.Child>

                    <div className="fixed z-10 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel
                                    className="w-6/12 fixed h-4/5 p-4 min-h-max self-center dark:bg-slate-700 bg-white rounded-lg overflow-y-scroll shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <Dialog.Title className="m-4 text-center font-bold text-xl dark:text-slate-100">Facturas</Dialog.Title>
                                    {
                                        props.facturas.facturas.length > 0 ?
                                            <div className='text-white dark:text-slate-100 dark:bg-slate-800 text-left rounded-md flex flex-col gap-4'>
                                                {
                                                    props.facturas.facturas.map((factura, index) =>
                                                        <div className='items-center gap-6
                                                                 flex justify-between text-sm font-light bg-gray-500 dark:text-slate-100 dark:bg-slate-800 p-4 rounded-md shadow-lg'>
                                                            <div className='flex flex-col'>
                                                                <p className='text-left'>DESCRIPCION: {props.facturas.descripcion}</p>
                                                                <p className='mt-1'>NRO: {factura.nro}</p>
                                                                <p className='mt-1'>PORCENTAJE
                                                                    FACTURADO: {factura.porcentaje} %</p>
                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                {
                                                                    !factura.path ?
                                                                        <div>
                                                                            <p className="bg-red-500 p-2 rounded-md text-white">Sin
                                                                                PDF</p>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <button
                                                                                onClick={() => handleView(factura.path)}
                                                                                className='bg-red-500 p-2 rounded-md'>
                                                                                <FontAwesomeIcon
                                                                                    className='h-10  w-10 text-white'
                                                                                    icon={faFilePdf}/></button>
                                                                        </div>
                                                                }

                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <p className='text-sm font-light bg-red-600 shadow-lg text-white text-left p-4 rounded-md'>No
                                                se ha cargado facturas en este item</p>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default ViewFacturas;