import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div className="h-screen">
            <section className="m-12 ">
                <h1 className="font-light text-3xl">No autorizado</h1>
                <br />
                <p className="font-extralight text-md">Usted no tiene autorización para entrar a esta página.</p>
                <div className="mt-6">
                    <button className="p-4 bg-black text-white font-light hover:bg-slate-600" onClick={goBack}>Volver</button>
                </div>
            </section>
        </div>
    )
}

export default Unauthorized
