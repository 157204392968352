import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

function NewElectrico(props) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [newStock, setNewStock] = useState(null)

    const [electrico, setElectrico] = useState({
        name: '',
        cantidad: '',
        precio: '',
        category: '',
        id: ''
    })




    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if(newStock){
            try {
                const response = await axiosPrivate.post('electricos', electrico)
                response.status === 201 &&
                props.success(true, `Electrico ${response.data.name} añadido`)
                props.refresh()
                setErr(null)
                setOpen(!open)
            } catch (error) {
                setErr(error.response.data.message)
                setLoading(false)
                throw new Error(`Error creating client: ${error.message}`)
            }
        } else {
            try {
                const response = await axiosPrivate.put(`electricos/updateQty/${electrico.id}`, electrico)
                props.success(true, `Electrico ${response.data.name} actualizado`)
                props.refresh()
                setErr(null)
                setOpen(!open)
            } catch (error) {
                setErr(error.response.data.message)
                setLoading(false)
                throw new Error(`Error actualizando stock: ${error.message}`)
            }
        }
        setNewStock(null)
        setLoading(false)
    }

    const handleRadioTypeNew = () => {
        setNewStock(true);
    }

    const handleRadioTypeAddStock = () => {
        setNewStock(false);
    }


    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className='bg-blue-500 hover:bg-blue-400 hover:duration-300 p-4 text-white rounded-md'>
                Añadir
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className=" fixed z-10 self-center" onClose={() => {
                    setOpen(false)
                    setErr(false);
                    setLoading(false)
                    setNewStock(null)
                    
                    
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"

                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="fixed h-fit min-h-max self-center dark:bg-slate-700 bg-white rounded-lg overflow-visible shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <Dialog.Title className="m-4 text-center font-medium dark:text-slate-100">Añadir stock electrico ⚡️</Dialog.Title>
                                    <div>
                                        <form className="w-full mt-6" onSubmit={(e) => handleSubmit(e)}>
                                            <div className='ml-4 pr-40'>
                                                <div className='flex flex-col gap-2'>
                                                    {err &&
                                                        <div className="relative group mb-6">
                                                            <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                        </div>
                                                    }
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <label className='mt-4 text-left text-sm font-light dark:text-slate-100'>Es nuevo o se añade stock?</label>
                                                    <div className='flex gap-4'>
                                                        <p className='font-extralight text-sm dark:text-slate-100'>Nuevo</p>
                                                        <input onClick={handleRadioTypeNew} type="radio" name='check' />
                                                        <p className='font-extralight text-sm dark:text-slate-100'>Se añade stock</p>
                                                        <input onClick={handleRadioTypeAddStock} type="radio" name='check' />
                                                    </div>

                                                </div>


                                                {

                                                    (newStock === null) ? null : <>
                                                        <div className='flex flex-col gap-2'>

                                                            {

                                                            newStock ?
                                                            <>
                                                                <label className='mt-4 text-left text-sm font-light dark:text-slate-100' htmlFor='nombre'>Descripción</label>
                                                                <input required onChange={(e) => {
                                                                    setElectrico({ ...electrico, name: e.target.value })
                                                                    setErr('')
                                                                }} className='font-extralight border p-2 dark:text-slate-100 dark:border-none dark:bg-slate-600' type='text' name='nombre' id='nombre' />
                                                            </>
                                                            :
                                                            <>
                                                                <label className='mt-4 text-left text-sm font-light dark:text-slate-100' htmlFor='nombre'>Descripción</label>
                                                                <select onChange={(e) => setElectrico({...electrico, id: e.target.value})} required className='border p-2 dark:text-slate-100 dark:bg-slate-600 dark:border-none'>
                                                                    <option  disabled selected value="">Elija un material</option>
                                                                    {
                                                                        props.electricos.map((opt, i) =>
                                                                            <option value={opt._id}>{opt.name}</option>
                                                                        )
                                                                    }

                                                                </select>
                                                            </>
                                                            }
                                                        </div>
                                                        <div className='flex flex-col gap-1'>
                                                            <label className='mt-4 text-left text-sm font-light dark:text-slate-100'>Cantidad</label>
                                                            <input step={0.01} required onChange={(e) => {
                                                                setErr('')
                                                                setElectrico({ ...electrico, cantidad: e.target.value })
                                                            }} className='font-extralight border p-2 dark:text-slate-100 dark:bg-slate-600 dark:border-none' type='number' />
                                                        </div>
                                                        {

                                                            newStock &&
                                                            <div className='flex flex-col gap-2'>
                                                                <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Precio</label>
                                                                <input required onChange={(e) => {
                                                                    setErr('')
                                                                    setElectrico({ ...electrico, precio: e.target.value })
                                                                }} className='border p-2 dark:text-slate-100 dark:border-none dark:bg-slate-600' type='number' step={0.001} name='precio' id='precio' />
                                                            </div>
                                                        }

                                                        <div className='flex flex-col gap-2'>
                                                            {
                                                                newStock &&
                                                                <>
                                                                    <label className='mt-4  text-left text-sm font-ligh dark:text-slate-100'>Categoria</label>
                                                                    <select onChange={(e) => setElectrico({...electrico, category: e.target.value})} required className='border p-2 dark:text-slate-100 dark:bg-slate-600 dark:border-none'>
                                                                        <option disabled selected value="">Elija categoria</option>
                                                                        <option value="Cable">Cable</option>
                                                                        <option value="Miscelaneos">Miscelaneos</option>
                                                                    </select>
                                                                </>
                                                            }

                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            <div className='flex justify-end mt-16'>
                                                <button className=' w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-600 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' type='submit'>
                                                    {
                                                        loading ?
                                                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            :
                                                            'Añadir'
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default NewElectrico;