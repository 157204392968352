import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../context/GlobalContext'
import useOcs from '../../hooks/useOcs'
import OC from './Oc'

const FacturacionDashboard = () => {
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0)
    const { queryOcs, setQueryOcs } = useGlobalContext()


    const {
        ocs,
        hasMore,
        loading,
    } = useOcs(queryOcs, pageNumber)

    const handleMin = (e) => {
        if (e === 'null') {
          setQueryOcs({ ...queryOcs, facturadoMin: '' })
          setPageNumber(0);
        } else {
            setQueryOcs({ ...queryOcs, facturadoMin: e.target.value })
            setPageNumber(0);
        }
      }
      const handleMax = (e) => {
        if (e === 'null') {
          setQueryOcs({ ...queryOcs, facturadoMax: '' })
          setPageNumber(0);
        } else {
            setQueryOcs({ ...queryOcs, facturadoMax: e.target.value })
            setPageNumber(0);
        }
      }
      const handleNro = (e) => {
        if (e === 'null') {
          setQueryOcs({ ...queryOcs, nro: '' })
          setPageNumber(0);
        } else {
            setQueryOcs({ ...queryOcs, nro: e.target.value })
            setPageNumber(0);
        }
      }
      const handleSitio = (e) => {
        if (e === 'null') {
          setQueryOcs({ ...queryOcs, obra: '' })
          setPageNumber(0);
        } else {
          setQueryOcs({ ...queryOcs, obra: e })
          setPageNumber(0);
        }
        
      }

    useEffect(() => {
        setQueryOcs({ ...queryOcs, obra: '' })
    }, [])


    const observer = useRef()
    const lastOcElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 10)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
        <>
            <div className='p-12'>
                <div className='flex gap-8'>
                <div className='flex flex-col'>
                        <label className='text-md dark:text-slate-100' htmlFor="nro">Número</label>
                        <div className='relative'>
                            <span  className='absolute ml-2 mt-2 dark:text-slate-100'><FontAwesomeIcon icon={faSearch} /></span>
                            <input onChange={(e) => handleNro(e)} id='nro' className='p-2 pl-10 dark:text-slate-100 outline-none bg-gray-100  dark:bg-slate-700' type="number" />
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='text-md dark:text-slate-100' htmlFor="min">Mínimo</label>
                        <input onChange={(e) => handleMin(e)} id='min' className='p-2 bg-gray-100 dark:bg-slate-700 dark:text-slate-100 outline-none' type="number" />
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-slate-100' htmlFor="max">Máximo</label>
                        <input onChange={(e) => handleMax(e)} id='max' className='p-2 bg-gray-100 dark:bg-slate-700 dark:text-slate-100 outline-none' type="number" />
                    </div>
                </div>
                <table className='w-full border-0 overflow-scroll border-separate border-spacing-y-4 mt-4'>
                    <thead>
                        <tr className='bg-gray-100 font-light dark:bg-slate-700'>
                            <th className='text-left font-light pl-4 rounded-tl-lg dark:text-slate-100'>Número</th>
                            <th className='text-left font-light pl-4 dark:text-slate-100'>Cliente</th>
                            <th className=' text-left font-light pl-4 dark:text-slate-100'>Sitio</th>
                            <th className=' text-left font-light dark:text-slate-100'>Tarea</th>
                            <th className=' text-left font-light dark:text-slate-100'>Facturado</th>
                            <th className='pl-12 text-left font-light dark:text-slate-100 rounded-tr-lg'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ocs.length > 0 && ocs.map((oc, index) => (
                                <tr  onClick={() => navigate(`${oc._id}`)} className='bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 h-12 dark:hover:bg-slate-500 hover:duration-300 hover:cursor-pointer' ref={lastOcElementRef}>
                                    <OC key={oc._id} oc={oc} />
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {
                    loading ?
                        <div className='self-center m-12 text-center' role="status">
                            <svg className="inline mr-2 w-8 h-8 text-slate-700 animate-spin dark:text-gray-600 fill-blue-600 dark:fill-slate-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        ocs.length === 0 &&

                        <div className='self-end text-center'>
                            <p className='text-2xl font-light whitespace-nowrap dark:text-slate-100'>No hay facturas cargadas</p>
                        </div>
                }
            </div>
        </>
    )
}

export default FacturacionDashboard