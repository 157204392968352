import React from "react";
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from "react-router-dom";


import {
    LocationMarkerIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    PaperAirplaneIcon,
    MenuIcon,
    UsersIcon,
    XIcon,
    ArchiveIcon,
} from '@heroicons/react/outline'
import useAuth from "../../hooks/useAuth";
import { useGlobalContext } from "../../context/GlobalContext";
import { AnimatePresence, motion } from "framer-motion";
import Switcher from "../themeSwitcher";




const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, current: false, authorization: ['user', 'admin', 'logistica'] },
    { name: 'Recursos', href: '/recursos', icon: UsersIcon, current: false, authorization: ['admin'] },
    
    {
        name: 'Obras',
        href: '/obras',
        icon: FolderIcon,
        current: false,
        authorization: ['admin']
    },
    {
        name: 'Stock',
        href: '/stock',
        icon: ArchiveIcon,
        current: false,
        authorization: ['admin', 'logistica']
    },
    {
        name: 'Pedidos',
        icon: PaperAirplaneIcon,
        current: false,
        href: '/pedidos',
        authorization: ['user', 'logistica', 'admin']
    },
    { name: 'Facturación', href: '/facturacion', icon: ChartBarIcon, current: false, authorization: ['admin'] },
    { name: 'Mapa', href: '/mapa', icon: LocationMarkerIcon, current: false, authorization: ['user', 'logistica', 'admin'] },
]



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Sidebar() {
    const { auth } = useAuth()
    const { isExpanded, setIsExpanded, UserCache } = useGlobalContext()
    const [sidebarOpen, setSidebarOpen] = useState(false)


    const handleCollapse = () => {
        if(isExpanded) {
            setIsExpanded(false);
            localStorage.setItem('collapsed', true);
            return;
        }
        setIsExpanded(true);
        localStorage.removeItem('collapsed')
    }
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog className="z-60" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="dark:bg-slate-700 bg-opacity-75" />
                    </Transition.Child>
                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel style={{ height: '100%', width: '100%' }} className="fixed flex-1 flex flex-col dark:bg-slate-700">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="fixed top-0 right-0 m-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex items-center justify-center h-10 w-10 focus:outline-none"
                                            onClick={() => setSidebarOpen(false)}
                                        >
                                            <XIcon className="h-6 w-6 dark:text-slate-100" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="flex-1 h-0 pt-5 pb-4">
                                    <div className="flex-shrink-0 flex items-center px-4 justify-between">
                                        <img
                                            className="h-16 w-auto"
                                            src="img/tera.png"
                                            alt="Terabyte_Logo"
                                        />
                                    </div>
                                    <nav className="mt-5 px-2">
                                        {navigation.map((item) =>
                                            item.authorization.includes(UserCache?.roles && UserCache.roles[0])
                                            &&
                                            <Link
                                                onClick={() => setSidebarOpen(false)}
                                                key={item.name}
                                                to={item.href}
                                                hidden={auth?.roles === item.authorization}
                                                className={classNames(
                                                    item.current
                                                        ? 'dark:bg-slate-600 dark:text-slate-100'
                                                        : 'dark:text-slate-100 dark:hover:bg-slate-800 dark:hover:text-slate-100',
                                                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'dark:text-gray-900' : 'dark:text-gray-400 dark:group-hover:text-gray-500',
                                                        'mr-4 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        )}
                                    </nav>
                                </div>
                                <div className="flex items-center dark:border-gray-200 p-4">
                                    <div className="flex ">
                                        <Link onClick={() => setSidebarOpen(false)} to={`profile/${UserCache.username}`} className="flex gap-4 flex-row w-full">
                                            <div>
                                                <img
                                                    className="h-12 w-12 rounded-full"
                                                    src={process.env.REACT_APP_DEFAULT_SERVER+UserCache?.image?.path}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="ml-3 self-center">
                                                <p className="text-xs font-medium dark:text-gray-700 dark:group-hover:text-gray-900">{UserCache.username}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
                    </div>
                </Dialog>
            </Transition.Root>






            {/* Static sidebar for desktop */}
                <div  className={`md:flex md:flex-col sm:hidden h-screen fixed`}>
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className={`flex flex-col`}>
                        <div className="flex flex-col pt-5 pb-4">
                            <div className="flex flex-col items-center">
                                <motion.button 
                                 initial={false}
                                 animate={isExpanded ? { x: 120, height: 0 } : { x: 0, height: 0}}
                              
                                className={`self-start ml-4 hover:scale-110 fixed dark:hover:text-gray-500 hover:duration-300 sm:hidden`} onClick={handleCollapse}>
                                    <MenuIcon className="h-6 w-6 dark:text-slate-100"/>
                                </motion.button>
                                        <motion.img
                                            initial={false}
                                            animate={isExpanded ? { y: 0, height: 60 } : { y: 40, height: 30}}
                                            
                                            className={`h-16 fixed self-start ml-3`} 
                                            src="/img/tera.png"
                                            alt="Terabyte"
                                        />
                            </div>
                            <nav className="mt-24 flex flex-col px-2 space-y-1" aria-label="Sidebar">
                                {navigation.map((item) =>
                                        item.authorization.includes(UserCache?.roles && UserCache.roles[0]) 
                                        &&
                                        <div className={!isExpanded && 'relative dark:hover:duration-300 before:z-10  before:shadow-md before:absolute before:-right-3 before:top-1/2 before:translate-x-full before:-translate-y-1/2 before:rounded-md before:bg-slate-600 Z-50  before:px-3 before:py-2 before:text-slate-100 before:invisible before:content-[attr(data-tip)] after:z-10 after:absolute after:-right-[0.8rem] after:top-1/2 after:h-0 after:w-0 after:translate-x-0 after:-translate-y-1/2 after:border-8 after:border-r-gray-600 after:border-l-transparent after:border-b-transparent after:border-t-transparent after:invisible hover:before:visible hover:after:visible'} data-tip={item.name} key={item.name}>
                                            <Link                                    
                                                to={item.href}
                                                className={classNames(
                                                    item.current
                                                        ? ' dark:text-slate-100 font-medium'
                                                        : ` dark:text-slate-100 font-medium hover:bg-slate-200 hover:duration-200 ${isExpanded && 'mr-10'} dark:hover:text-slate-900`,
                                                    'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.current ? 'dark:text-slate-100 font-medium' : 'dark:text-slate-100 font-medium group-hover:text-slate-900',
                                                        'mr-3 flex-shrink-0 h-6 w-6'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                <AnimatePresence>
                                                {
                                                    isExpanded &&
                                                        <p>
                                                            {item.name}
                                                        </p>
                                                }
                                                </AnimatePresence> 
                                            </Link>
                                        </div>
                                )}
                            </nav>
                        </div>
                        <div className="flex justify-center">
                            
                        </div>
                        <div className="flex flex-col p-4 gap-10 bottom-0 fixed">
                            <div>
                                <Switcher />
                            </div>
                            <Link to={`profile/${UserCache.username}`} className="flex-shrink-0 w-full group block">
                                <div className="flex flex-row items-center gap-6">
                                    <img
                                        className="h-8 w-8 rounded-full self-center"
                                        src={process.env.REACT_APP_DEFAULT_SERVER+UserCache?.image?.path}
                                        alt="Profile"
                                    />
                                            {
                                            isExpanded &&
                                                <div
                                                >
                                                    <p className="self-end items-end justify-center font-medium dark:text-slate-100 text-xs">Ver perfil</p>
                                                </div>
                                            }
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="fixed top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 ">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md dark:text-white hover:text-slate-200"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </>
    )
}
