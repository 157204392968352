import React from 'react'
import useAuth from '../../hooks/useAuth'
import ViewPreparing from './ViewPreparing';



const Preparacion = (props) => {

    const { auth } = useAuth();
    const preparing = true;

    return (
        <>
            {


                auth.roles.includes('user') ?

                    <ul
                        className="flex flex-col gap-2 mt-6"
                    >

                        {
                            props.pedidos.map(item =>
                                item.estado === 'En preparación' ?
                                    <li key={item._id} className="flex flex-row justify-between border shadow-sm px-4 py-1 rounded-md hover:bg-gray-200">
                                        <p className="font-light text-xs py-1">{item._id}</p>
                                        {
                                            preparing &&
                                            <span className="relative inline-flex">
                                                <button disabled type="button" className="inline-flex items-center px-4 cursor-default font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white  transition ease-in-out duration-150  ring-1 ring-slate-900/10" >
                                                    {item.estado}
                                                </button>
                                                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                    <span className="absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                                </span>
                                            </span>
                                        }
                                        <ViewPreparing pedido={item} electricos={props.stock} refresh={props.refresh} />
                                    </li>
                                    :
                                    <>{null}</>
                            )
                        }
                    </ul>


                    :
                    <ul
                        className="flex flex-col gap-2 mt-6"
                    >
                        {

                            props.pedidos.map(item =>

                                item.estado === 'En preparación' ?

                                    <li key={item._id} className="flex flex-row justify-between border px-4 py-1 shadow-sm rounded-md hover:bg-gray-200">
                                        <p className="font-light text-xs py-1">{item._id}</p>
                                        {
                                            preparing &&
                                            <span className="relative inline-flex">
                                                <button type="button" disabled className="inline-flex items-center px-4 font-semibold leading-6 text-sm shadow rounded-md text-sky-500 bg-white transition ease-in-out duration-150 cursor-default ring-1 ring-slate-900/10 ">
                                                    {item.estado}
                                                </button>
                                                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                    <span className="absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                                </span>
                                            </span>
                                        }

                                        <ViewPreparing pedido={item} electricos={props.stock} refresh={props.refresh} />
                                    </li>
                                    :
                                    null
                            )}

                    </ul>
            }
        </>
    )
}

export default Preparacion;