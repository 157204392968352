import { useEffect, useState } from 'react'
import axios from 'axios'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useGlobalContext } from '../context/GlobalContext'

export default function useInvoices(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const axiosPrivate = useAxiosPrivate();
  const { invoices, setInvoices } = useGlobalContext()
  
  useEffect(() => {
    setInvoices([])
    pageNumber = 0
  }, [query])

  
  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel
    const queryEjecutor = query.ejecutor ? (query?.ejecutor !== '' ? `&ejecutor=${query.ejecutor}` : '') : ''
    const queryObra = query.obra ? (query?.obra !== '' ? `&obra=${query.obra}` : '') : ''
    const queryTarea = query.tarea ? (query?.tarea !== '' ? `&tarea=${query.tarea}` : '') : ''
    const queryId = query.nro ? (query?.id !== '' ? `&id=${query.id}` : '') : ''

    axiosPrivate.get(`/invoicesQuery?skip=${pageNumber}${queryEjecutor}${queryTarea}${queryId}${queryObra}`, {
      cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(res => {
            setInvoices(prevInvoices => {
              return [...new Set([...prevInvoices, ...res.data.map(b => b)])]
                })
            setHasMore(res.data.length > 0)
            setLoading(false)
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
    })
    return () => cancel()
  }, [query, pageNumber])

  return { loading, error, invoices, hasMore, setInvoices }
}