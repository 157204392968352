import { DocumentDownloadIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline'

import React, { useState } from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Invoice = (props) => {
    const [spinnerDelete, setSpinnerDelete] = useState(false)
    const [spinnerDownload, setSpinnerDownload] = useState(false)
    const [spinnerView, setSpinnerView] = useState(false)
    const axiosPrivate = useAxiosPrivate();

    const handleDelete = async (id) => {
        props.error(null)
        try {
            setSpinnerDelete(true)
            const response = await axiosPrivate.delete(`invoice/${id}`);
            props.delete(response.data._id)
            setSpinnerDelete(false)
        } catch (error) {
            setSpinnerDelete(false)
            props.error(error.response.statusText)
            throw new Error(`Error: ${error}`)
        }         
    }

    const handleView = async (path) => {
        props.error(null)
        setSpinnerView(true)
        const encodedPath = encodeURIComponent(path)
        try {
            const { data } = await axiosPrivate.get(`/file/${encodedPath}`, { responseType: 'blob' });
            const blob = new Blob([data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
            setSpinnerView(false)
        } catch (error) {
            setSpinnerView(false)
            props.error(error.response.statusText)
            throw new Error(`Error: ${error} `);
        }
        
    }

    const handleDownload = async (path, fileName) => {
        props.error(null)
        setSpinnerDownload(true)
        const encodedPath = encodeURIComponent(path)

        try {
            const { data } = await axiosPrivate.get(`file/${encodedPath}`, { responseType: 'blob' });
            const blob = new Blob([data], { type: "application/pdf" });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.pdf`;
            link.click();
            setSpinnerDownload(false)
        } catch (error) {
            setSpinnerDownload(false)
            props.error(error.response.statusText)
            throw new Error(`Error: ${error} `);
        }
    }








    return (
        <>
            {
                props.invoice.obra === null ?
                    <>
                        <td className='text-center'>{props.invoice._id}</td>
                        <td className='text-left font-medium text-red-500' colSpan={3}>OC
                            correspondiente a obra eliminada
                        </td>
                        <td className='table-cell text-center '>
                            <button onClick={() => handleView(props.invoice.path)}><EyeIcon
                                className='pt-2 w-8 h-8' /></button>
                            <button onClick={() => handleDownload(props.invoice.path, props.invoice._id)}
                                className='rounded-md text-black dark:text-slate-100'>
                                <DocumentDownloadIcon className="pt-2 w-8 h-8 text-black dark:text-slate-100" />
                            </button>
                            <button onClick={() => handleDelete(props.invoice._id)}
                                className='rounded-md '><TrashIcon
                                    className="w-8 h-8 text-black dark:text-slate-100 pt-2" /></button>
                        </td>
                    </>
                    :
                    <>
                        <td className='table-cell text-center '>{props.invoice._id}</td>
                        <td className='table-cell text-center '>{props.invoice?.obra?.SITIO.map(({ SITIO }) => SITIO).join(' - ')}</td>
                        <td className='table-cell text-center '>{props.invoice?.obra?.TAREA}</td>
                        <td className='table-cell text-center '>{props.invoice?.obra?.CONTRATISTA.name}</td>
                        <td className='table-cell text-center '>
                            <button onClick={() => handleView(props.invoice.path)}>
                                {
                                    spinnerView ?
                                        <svg
                                            className="animate-spin h-4 w-4 mx-2 -mt-5 text-black dark:text-white"
                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12"
                                                r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        :
                                        <EyeIcon
                                            className='pt-2 w-8 h-8' />
                                }
                            </button>
                            <button onClick={() => handleDownload(props.invoice.path, props.invoice._id)}
                                className='rounded-md text-black'>
                                {
                                    spinnerDownload ?
                                        <svg
                                            className="animate-spin h-4 w-4 mx-2 -mt-5 text-black dark:text-white"
                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12"
                                                r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        :
                                        <DocumentDownloadIcon className="pt-2 w-8 h-8 text-black dark:text-slate-100" />
                                }
                            </button>
                            <button onClick={() => handleDelete(props.invoice._id)}
                                className='rounded-md '>
                                {
                                    spinnerDelete ?
                                        <svg
                                            className="animate-spin h-4 w-4 -mt-5 text-red-500"
                                            xmlns="http://www.w3.org/2000/svg" fill="none"
                                            viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12"
                                                r="10" stroke="currentColor"
                                                strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        :
                                        <TrashIcon className="w-8 h-8 text-black dark:text-slate-100 pt-2" />
                                }

                            </button>
                        </td>
                    </>
            }
        </>
    )
}

export default Invoice