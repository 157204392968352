import React from 'react'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react';
import MapaObra from './MapaObra';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react';
import EstadoObra from './EstadoObra';
import LoadingSpinner from '../../../LoadingSpinner';
import EjecutorObra from './EjecutorObra';


const VIewObra = () => {
  const { tarea, cliente } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [obra, setObra] = useState({})
  const [loading, setLoading] = useState(false)
  const rentabilidad = 
      parseFloat(obra?.ORDENES_COMPRA?.reduce((acc, oc) => acc + oc.items?.reduce((count, item) => count + item.total, 0), 0))
      -
      (parseFloat(obra?.PEDIDO?.reduce((acc, pedido) => acc + pedido.materiales?.reduce((count, material) => count + (material.precio * material.cantidad), 0), 0))
      +
      parseFloat(obra?.ORDENES_COMPRA_PROVEEDORES?.reduce((acc, oc) => acc + oc.items?.reduce((count, item) => count + item.total, 0), 0))
      +
      parseFloat(obra?.GASTOS_VARIOS?.reduce((acc, gasto) => acc + gasto.monto, 0)))

  const getObra = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(`obra/${tarea}`)
      console.log(response)
      setObra(response.data);
    } catch (error) {
      throw new Error(error.response)
    }
    setLoading(false);

  }



  useEffect(() => {
    getObra();
  }, [])


  return (



    <div className='dark:bg-slate-800 h-screen'>
      {
        loading
          ?
          <div className='grid grid-cols-12 h-screen dark:bg-slate-800'>
            <div className='col-start-7 self-center'>
              <LoadingSpinner />
            </div>
          </div>
          :
          obra.SITIO &&
          <div className='grid grid-cols-12 grid-rows-6 gap-x-12 h-screen'>
            <div className='col-start-2 col-end-12 row-start-1 row-end-3 mt-6 overflow-hidden rounded-lg shadow-xl'>
              <MapaObra sitios={obra.SITIO} />
            </div>

            <div className='col-start-2 col-end-4 row-start-3 dark:bg-slate-700 dark:text-slate-100 row-end-4 self-center shadow-xl rounded-xl '>

              <EstadoObra refresh={(e) => setObra(e)} estado={obra.ESTADO} />

            </div>
            <div className='col-start-4 col-end-6 row-start-3 row-end-4 self-center  dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <div className='w-full h-full p-4'>
                <p className='text-sm text-left font-light dark:text-slate-100'>Sitio</p>
                <p className='text-sm text-left sm:text-xs font-extralight dark:text-slate-100'>{obra.SITIO.map(({ SITIO }) => SITIO).join(' - ')}</p>
              </div>
            </div>
            <div className='col-start-6 col-end-8 row-start-3 row-end-4 self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <div className='w-full h-full p-4'>
                <p className='text-sm text-left font-light dark:text-slate-100'>Tarea</p>
                <p className='text-sm text-left font-extralight dark:text-slate-100'>{obra.TAREA}</p>
              </div>
            </div>
            <div className='col-start-8 col-end-10 row-start-3 row-end-4 self-center dark:text-slate-100 dark:bg-slate-700  rounded-lg shadow-xl'>
              <EjecutorObra refresh={(e) => setObra(e)} ejecutor={obra.CONTRATISTA} />
            </div>

            <div className='col-start-10 col-end-12 row-start-3 row-end-4 self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <div className='w-full h-full p-4'>
                <p className='text-md font-light dark:text-slate-100'>Cliente</p>
                <p className='text-sm font-extralight dark:text-slate-100'>{obra?.CLIENTE?.nombre}</p>
              </div>
            </div>

            <div className='col-start-7 col-end-12 row-start-4 row-end-5 self-center  dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <Link to='gastos'>
                <button className='w-full h-full p-4 dark:hover:bg-slate-600 hover:duration-300'>
                  <p className='text-md text-left font-light dark:text-slate-100'>Gastos</p>
                  <p className='text-sm text-left font-extralight dark:text-slate-100 w-full'>Pedidos, ordenes de compra a proveedores y gastos varios</p>
                </button>
              </Link>
            </div>
            <div className='col-start-2 col-end-7 row-start-4 row-end-5  self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <Link to='ocs'>
                <button className='w-full h-full p-4 dark:hover:bg-slate-600 hover:duration-300'>
                  <div className='flex gap-4'>
                    <FontAwesomeIcon icon={faFileInvoice} className="self-center dark:text-slate-100" />
                    <p className='text-md  font-light dark:text-slate-100'>Ordenes de compra</p>
                  </div>
                  <p className='text-sm text-left pl-7 font-extralight w-full dark:text-slate-100'>{`Ordenes de compra cargadas: ${obra.ORDENES_COMPRA.length}`}</p>
                </button>
              </Link>
            </div>
            <div className='col-start-7 col-end-9 row-start-5 row-end-6 self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <div className='w-full h-full p-4'>
                <p className='text-md font-light dark:text-slate-100'>Fecha de inicio</p>
                <p className='text-sm font-extralight dark:text-slate-100'>{new Date(obra.FECHA_INICIO).toLocaleDateString()}</p>
              </div>
            </div>
            <div className='col-start-9 col-end-12 row-start-5 row-end-6 self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <div className='w-full h-full p-4'>
                <p className='text-md font-light dark:text-slate-100'>Fecha de fin</p>
                <p className='text-sm font-extralight dark:text-slate-100'>{new Date(obra.FECHA_FIN).toLocaleDateString()}</p>
              </div>
            </div>
            <div className='col-start-2 col-end-7 row-start-5 row-end-6 self-center dark:bg-slate-700 rounded-lg overflow-hidden shadow-xl'>
              <button className='w-full h-full p-4 '>
                <div className='flex justify-between'>
                  <div>
                    <p className='text-md text-left font-light dark:text-slate-100'>Rentabilidad</p>
                    <p className='text-xs text-left font-extralight dark:text-slate-100'>Gastos y ganancias obtenidas</p>
                  </div>
                  <div className='flex items-center gap-1'>
                    <p className={`${rentabilidad < 0 ? 'dark:text-red-300 text-red-500' : 'dark:text-green-300 text-green-500'}`}>
                      $ {rentabilidad.toLocaleString('es-AR', {minimumFractionDigits: 2})}
                      </p>
                  </div>
                </div>
              </button>
            </div>

          </div>
      }
    </div>
  )
}

export default VIewObra;