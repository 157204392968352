import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import AsyncSelect from 'react-select/async'
import Select from 'react-select'



function NewObra(props) {
    const axiosPrivate = useAxiosPrivate();
    const [celdas, setCeldas] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [obra, setObra] = useState(
        {
            tarea: '',
            contratista: [],
            fecha_inicio: '',
            fecha_fin: '',
        }
    )

    const getCeldas = async (inputText, callback) => {
        try {
            const response = await axiosPrivate.get(`celdas/${inputText.toUpperCase()}`);
            callback(response.data.map(i => ({ value: { SITIO: i.SITIO, NOMBRE_SITIO: i.NOMBRE_SITIO, position: { lat: i.LATITUD, lng: i.LONGITUD }, _id: i._id }, label: `${i.SITIO} - ${i.NOMBRE_SITIO}` })))
        } catch (error) {
            throw new Error(error);
        }
    }

    const handleCeldaChange = (e) => {
        setCeldas(e)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);
        try {
            const response = await axiosPrivate.post('obras', obra)
            response.status === 201 &&
                setLoading(false)
            props.success(true, `Obra ${response.data.TAREA} añadida`)
            props.refresh(response.data)
            setCeldas([])
            setErr(null)
            setOpen(!open)
        } catch (error) {
            setLoading(false)
            setErr(error.response.data.message)
            throw new Error(error)
        }
    }


    let isDark = localStorage.getItem('theme') === 'dark'
    const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? 'rgba(49,59,76,1)' : 'white',
                border: 'none',
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? '#333' : '#FFF',
                '&:hover': {
                    backgroundColor: isDark ? 'rgba(57,65,83,1)' : '#DDD',
                },
                color: isDark ? '#FFF' : '#333',
                borderRadius: '0',
                textAlign: 'left',

                // Añade otras propiedades de estilo según tus necesidades
            }),
            menu: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? '#333' : '#FFF',
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),

            input: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValue: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',

                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValueRemove: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
        }
    



    return (
        <>
            <button
                onClick={() => setOpen(true)}
                className='mb-4 px-6 font-md bg-gray-200 hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-600 p-2 dark:text-slate-100 text-sm rounded-md'>
                Añadir obra
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className=" fixed z-10 self-center" onClose={() => {
                    setErr(false);
                    setOpen(false)
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-black fixed inset-0 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                <Dialog.Panel className="fixed  self-center bg-gray-200 dark:bg-slate-800 rounded-lg overflow-visible shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <Dialog.Title className="m-4 text-center font-medium dark:text-slate-100">Añadir Obra</Dialog.Title>
                                    <div>
                                        <form className="w-full mt-6" onSubmit={(e) => handleSubmit(e)}>
                                            <div className='ml-4 pr-40'>
                                                <div className='flex flex-col gap-2'>
                                                    {err &&
                                                        <div className="relative group mb-6">
                                                            <p className={err ? "dark:bg-slate-800text-slate-100 relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='flex flex-col gap-2'>
                                                    <label className='text-left text-sm font-light dark:bg-slate-800 dark:text-slate-100' htmlFor='nombre'>Cliente</label>
                                                    <Select
                                                        id='select'
                                                        onChange={(e) => setObra({ ...obra, cliente: e.value })}
                                                        placeholder="Ingrese cliente..."
                                                        noOptionsMessage={() => 'No hay clientes'}
                                                        styles={customStyles}
                                                        options={props.clientes.map(cliente => ({ label: cliente.nombre, value: cliente._id }))}
                                                    />
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <label className='mt-4 text-left text-sm font-light dark:bg-slate-800 dark:text-slate-100'>Tarea</label>
                                                    <input required onChange={(e) => {
                                                        setErr('')
                                                        setObra({ ...obra, tarea: e.target.value })
                                                    }} className='font-extralight h-6 py-4 rounded-sm dark:text-slate-100 dark:bg-slate-700 pl-2 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='text' name='apellido' id='apellido' />
                                                </div>
                                                <div className='flex flex-col gap-2 text-left'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Proyecto</label>
                                                    <Select
                                                        id='select'
                                                        onChange={(e) => setObra({ ...obra, proyecto: e.value })}
                                                        placeholder="Ingrese proyecto..."
                                                        noOptionsMessage={() => 'No hay proyectos'}
                                                        styles={customStyles}
                                                        options={props.proyectos.map(proyecto => ({ label: proyecto.name, value: proyecto._id }))}
                                                    />
                                                </div>
                                                <div className='flex flex-col gap-2 text-left'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Sitio</label>
                                                    <AsyncSelect
                                                        id='select'
                                                        isMulti
                                                        value={celdas}
                                                        onChange={(e) => {
                                                            handleCeldaChange(e)
                                                            setObra({ ...obra, sitio: e.map(sitio => sitio.value._id) })
                                                        }}
                                                        styles={customStyles}
                                                        noOptionsMessage={() => 'No hay sitios'}
                                                        placeholder="Ingrese Celda..."
                                                        loadOptions={getCeldas}
                                                    />
                                                </div>

                                                <div className='flex flex-col gap-2 text-left'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Ejecutor</label>
                                                    <Select
                                                        id='select'
                                                        styles={customStyles}
                                                        onChange={(e) => setObra({ ...obra, contratista: e.value })}
                                                        placeholder="Ingrese proveedor..."
                                                        noOptionsMessage={() => 'No hay proveedores'}
                                                        options={props.contratistas.map(contratista => ({ label: contratista.name, value: contratista._id }))}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex justify-end mt-16'>
                                                <button className=' w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-500 dark:bg-slate-900 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' type='submit'>
                                                    {
                                                        loading ?
                                                            <svg className="animate-spin h-6 w-6 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            :
                                                            'Añadir'
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default NewObra;