import React from 'react'

const Gasto = (props) => {
    return (
        <>
            <td className='p-4 dark:text-slate-100 border dark:border-slate-600'>{props?.gasto?.descripcion}</td>
            <td className='p-4 dark:text-slate-100 border dark:border-slate-600'>$ {(props?.gasto?.monto)?.toLocaleString('es-AR', { minimumFractionDigits: 2})}</td>
        </>
    )
}

export default Gasto