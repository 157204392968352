import React from 'react'
import { CurrencyDollarIcon, XIcon } from '@heroicons/react/outline'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useState } from 'react'
import Select from 'react-select'
import { useNavigate } from 'react-router-dom'
import AsyncSelect from 'react-select/async'


const CreateInvoice = () => {

    const axiosPrivate = useAxiosPrivate();
    const [obra, setObra] = useState({})
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const handleSetObra = (e) => {
        setObra(e)
        setInvoice({ ...invoice, obra: e.value._id, contratista: e.value.CONTRATISTA._id })
    }



    const condiciones =
        [
            'Cheque de pago diferido',
            'Transferencia bancaria',
            'Contado',
        ]

    const [item, setItem] = useState({
        descripcion: '',
        precioUnitario: 0,
        cantidad: 0,
        total: 0
    })

    const [invoice, setInvoice] = useState({
        contratista: '',
        condicion: '',
        obra: '',
        items: [],
    })


    const getObras = async (inputText, callback) => {

        try {
            const response = await axiosPrivate.get(`obraregex/${inputText.toUpperCase()}`);
            callback(response.data.map(i => ({ value: i, label: `${i.TAREA}` })))
        } catch (error) {
            throw new Error(error);
        }
    }
    const handleForm = (e) => {
        e.preventDefault();
        setInvoice({ ...invoice, items: [...invoice.items, item] })
    }

    const handleDeleteItem = (e) => {
        const newItems = [...invoice.items]
        newItems.splice(e, 1)
        setInvoice({ ...invoice, items: newItems })
    }

    const handleGeneratePurchaseOrder = async () => {
        setLoading(true)
        const date = new Date()

        try {
            const response = await axiosPrivate.post(
                `invoice/${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`,
                invoice
            )
            navigate('/oc')
            setLoading(false)
        } catch (error) {
            setError(error.response.data.message)
            throw new Error(error.response)
        }
    }

    let isDark = localStorage.getItem('theme') === 'dark' ? true : false;
    const customStyles = {
            control: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? 'rgba(74,85,103,1)' : 'rgba(229,231,235)',
                border: 'none',
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? '#333' : '#FFF',
                '&:hover': {
                    backgroundColor: isDark ? 'rgba(57,65,83,1)' : '#DDD',
                },
                color: isDark ? '#FFF' : '#333',
                borderRadius: '0',
                textAlign: 'left',

                // Añade otras propiedades de estilo según tus necesidades
            }),
            menu: (provided, state) => ({
                ...provided,
                backgroundColor: isDark ? '#333' : '#FFF',
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),

            input: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValue: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',

                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValueLabel: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
            multiValueRemove: (provided, state) => ({
                ...provided,
                color: isDark ? '#FFF' : '#333',
                // Añade otras propiedades de estilo según tus necesidades
            }),
        }
    

    return (
        <div className="w-full h-screen sm:h-100 sm:w-100 mt-12 md:px-6  sm:p-2   sm:pt-16 " >
            <div className="flex flex-col h-4/5 dark:bg-slate-700 bg-white shadow-md rounded-lg overflow-y-scroll">
                <div className="flex">
                    <CurrencyDollarIcon className="w-8 m-4 text-gray-500 dark:text-slate-100" />
                    <p className="mt-5 text-gray-500 dark:text-slate-100 ">Ordenes de compra</p>
                </div>
                {
                    error &&
                    <div className='mt-6'>
                        <p className="p-4 text-white bg-red-600 dark:text-slate-100">Ordenes de compra</p>
                    </div>
                }
                <div className="m-4 mt-10">
                    <form onSubmit={(e) => handleForm(e)} className="w-full grid grid-cols-12 grid-rows-6 gap-x-8">

                        <div className="col-start-1 col-end-4 flex flex-col">
                            <label className="text-gray-500 dark:text-slate-100 mb-2">Condicion</label>
                            <Select onChange={(e) => setInvoice({ ...invoice, condicion: e.value })} required styles={customStyles} options={condiciones.map(condicion => ({ value: condicion, label: condicion }))} />
                        </div>
                        <div className="col-start-4 col-end-7 flex flex-col">
                            <label className="text-gray-500 dark:text-slate-100">Obra</label>

                            {
                                <div className="mt-2">
                                    <AsyncSelect
                                        id='select'
                                        onChange={(e) => handleSetObra(e)}
                                        placeholder="Ingrese tarea..."
                                        loadOptions={getObras}
                                        styles={customStyles}
                                    />
                                </div>
                            }
                        </div>
                        <div className="col-start-7 col-end-10 flex flex-col">
                            <label className="text-gray-500 dark:text-slate-100">Proveedor</label>
                            <input value={obra.value ? obra.value.CONTRATISTA.name : null} type="text" disabled className="mt-2 text-black border disabled:bg-gray-200 dark:text-slate-100 dark:bg-slate-600 dark:border-none p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />

                        </div>
                        <div className="col-start-10 col-end-13 flex flex-col">
                            <label className="text-gray-500 mb-2 dark:text-slate-100">Sitio</label>
                            <input value={obra.value ? obra.value.SITIO.map(({ SITIO }) => SITIO).join('-') : null} type="text" disabled className="dark:text-slate-100 dark:bg-slate-600 dark:border-none text-black disabled:bg-gray-200  border p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />

                        </div>
                        <div className="col-start-1 col-end-3 row-start-3 self-center">
                            <label className="text-gray-500 dark:text-slate-100">Descripción</label>
                            <input onChange={(e) => setItem({ ...item, descripcion: e.target.value })} type="text" className="dark:text-slate-100 dark:bg-slate-600 dark:border-none mt-2 text-black border p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />
                        </div>
                        <div className="col-start-4 col-end-6 row-start-3">
                            <label className="text-gray-500 dark:text-slate-100">Precio unitario</label>
                            <div className='relative'>
                                <span className='absolute mt-4 ml-2 text-gray-500 dark:text-slate-100'>$</span>
                                <input onChange={(e) => setItem({ ...item, precioUnitario: e.target.value, total: e.target.value * item.cantidad })}
                                    step="0.01"
                                    id="precioUnitario"
                                    type="number"
                                    value={item.precioUnitario}
                                    className="mt-2 pl-6 dark:text-slate-100 dark:bg-slate-600 dark:border-none text-gray-500 border p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />
                            </div>

                        </div>
                        <div className="col-start-7 col-end-9 row-start-3 self-center">
                            <label className="text-gray-500 dark:text-slate-100">Cantidad</label>
                            <input defaultValue={0} onChange={(e) => setItem({ ...item, cantidad: e.target.value, total: e.target.value * item.precioUnitario })} type="number" className="dark:text-slate-100 dark:bg-slate-600 dark:border-none mt-2 pl-2 text-gray-500 border p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />

                        </div>
                        <div className="col-start-10 col-end-13 row-start-3 self-center flex flex-col">
                            <label className="text-gray-500 dark:text-slate-100">Total</label>
                            <div className='relative'>
                                <span className='absolute mt-4 ml-2 text-gray-500 dark:text-slate-100'>$</span>
                                <input value={(item.total).toFixed(2)} type="number" disabled className="dark:text-slate-100 dark:bg-slate-600 dark:border-none mt-2 pl-6 text-gray-500 border p-2 text-left rounded-md outline-none focus:ring focus:ring-blue-500 focus:duration-200 focus:shadow-md" />
                            </div>
                        </div>
                        {
                            (item.descripcion.length > 0 && item.precioUnitario > 0 && item.cantidad > 0) &&
                            <div className="col-start-1 col-end-3 row-start-4 self-center">
                                <button className="bg-violet-600 hover:bg-violet-400 hover:duration-200 text-white p-2 rounded-lg">Añadir Item</button>
                            </div>
                        }
                    </form>
                    {
                        invoice.items.length > 0 &&
                        <div className="grid grid-cols-12 -mt-16 ">
                            <table className="col-start-1 col-end-13 ">
                                <thead>
                                    <tr className='py-4'>
                                        <th className="py-2 text-gray-500 dark:bg-slate-600 dark:text-slate-100 bg-gray-100 rounded-l-lg">Descripción</th>
                                        <th className="py-2 text-gray-500 dark:bg-slate-600  dark:text-slate-100 bg-gray-100 ">Precio unitario</th>
                                        <th className="py-2 text-gray-500 dark:bg-slate-600  dark:text-slate-100 bg-gray-100 ">Cantidad</th>
                                        <th className="py-2 text-gray-500 dark:bg-slate-600  dark:text-slate-100 bg-gray-100 ">Subtotal</th>
                                        <th className="py-2 text-gray-500 dark:bg-slate-600  dark:text-slate-100 bg-gray-100 rounded-r-lg" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoice.items.map((i, index) =>

                                            <tr key={index} className='border-b bg-gray-100 dark:bg-slate-700 dark:border-slate-600  dark:text-slate-100'>
                                                <td className="text-center">{i.descripcion}</td>
                                                <td className="text-center">
                                                    $ {i.precioUnitario.toLocaleString('es-AR', { minimumFractionDigits: 2 })}
                                                </td>
                                                <td className="text-center">{i.cantidad}</td>
                                                <td className="text-center">$ {i.total.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                <td className="text-center p-1"><button onClick={() => handleDeleteItem(index)} className='p-2 border bg-red-500 dark:border-none rounded-lg shadow-md'><XIcon className='w-4 text-white' /></button></td>
                                            </tr>
                                        )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                {
                    (invoice.items.length > 0 && invoice.condicion && invoice.contratista && invoice.obra) &&
                    <div className="flex justify-end mb-2">
                        <button onClick={() => handleGeneratePurchaseOrder()} className="p-2 px-4 mr-4 mt-16 text-white rounded-lg bg-violet-500 hover:bg-violet-400">
                            {
                                loading ?
                                    <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    :
                                    <p>Generar</p>
                            }

                        </button>
                    </div>
                }
            </div>
        </div >
    )
}

export default CreateInvoice;