import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";


const GlobalContext = createContext({});

export const useGlobalContext = () => useContext(GlobalContext);


export const GlobalProvider = ({ children }) => {
    const [ celdas, setCeldas ] = useState([])
    const [ userImage, setUserImage ] = useState('')
    const sidebarCollapsed = localStorage.getItem('collapsed')
    const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true)
    const [refreshIsLoading, setRefreshIsLoading] = useState(true);
    const [ obras, setObras ] = useState([])
    const [ invoices, setInvoices ] = useState([])
    const [ ocs, setOcs ] = useState([])
    const [ UserCache, setUserCache ] = useState({});
    const [selectedsFromObrasQuery, setSelectedsFromObrasQuery] = useState([]);
  
    const [queryObras, setQueryObras] = useState({
        estado: '',
        proyecto: '',
        ejecutor: '',
        cliente: '',
        sitio: '',
      })

      const [queryOcs, setQueryOcs] = useState({
        nro: '',
        obra: '',
        facturadoMin: '',
        facturadoMax: '',
      })

      const [queryInvoices, setQueryInvoices] = useState({
        ejecutor: '',
        obra: '',
        tarea: '',
        id: '',
      })


    return (
        <GlobalContext.Provider value={{ 
            celdas,
            UserCache,
            userImage,
            isExpanded,
            obras,
            queryObras,
            ocs,
            queryOcs,
            invoices,
            queryInvoices,
            refreshIsLoading,
            selectedsFromObrasQuery,
            setQueryOcs,
            setSelectedsFromObrasQuery,
            setQueryObras,
            setInvoices,
            setQueryInvoices,
            setUserCache,
            setIsExpanded,
            setCeldas,
            setUserImage,
            setRefreshIsLoading,
            setObras,
            setOcs
            }}>
            {children}
        </GlobalContext.Provider>
    )
}

