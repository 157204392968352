import { DocumentDownloadIcon, EyeIcon, PlusIcon, ReceiptTaxIcon, TrashIcon } from '@heroicons/react/outline'
import React from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';



const OCProveedores = () => {
    const { cliente, tarea } = useParams();
    const [success, setSuccess] = useState({ state: false, data: '' });
    const [invoices, setInvoices] = useState([]);
    const [obra, setObra] = useState({});
    const [loading, setLoading] = useState(true)
    const axiosPrivate = useAxiosPrivate();

    const getInvoices = async () => {
      setLoading(true);
      try {
        const response = await axiosPrivate.get(`obra/${cliente}/${tarea}`)
        setObra(response.data)
        setInvoices(response.data.ORDENES_COMPRA_PROVEEDORES)
      } catch (error) {
        throw new Error(error.response)
      }
      setLoading(false);
    }

    const handleDelete = async (id) => {
        try {
            setLoading(true)
            const response = await axiosPrivate.delete(`invoice/${id}`);
            getInvoices()
            setLoading(false)
        } catch (error) {
            throw new Error(`Error: ${error}`)
        }
    }

    const handleView = async (path) => {
        const encodedPath = encodeURIComponent(path)
        try {
            const { data } = await axiosPrivate.get(`/file/${encodedPath}`, { responseType: 'blob' });
            const blob = new Blob([data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(blob);
            window.open(fileURL);
        } catch (error) {
            throw new Error(`Error: ${error} `);
        }
    }

    const handleDownload = async (path, fileName) => {
        const encodedPath = encodeURIComponent(path)

        try {

            const { data } = await axiosPrivate.get(`file/${encodedPath}`, { responseType: 'blob' });
            const blob = new Blob([data], { type: "application/pdf" });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}.pdf`;
            link.click();
        } catch (error) {
            throw new Error(`Error: ${error} `);
        }
    }

    useEffect(() => {
        getInvoices();
    }, [])

    return (
        <div className="w-full sm:w-100 sm:h-100 p-4 sm:p-2 sm:pt-16 ">
            <div className="flex flex-col sm:h-100 sm:w-10">
                <div className="grid grid-cols-12">
                    <div className="col-start-1 col-end-13 flex flex-row justify-between">
                        <div className="flex gap-2">
                            <ReceiptTaxIcon className="w-8 self-start m-2 text-gray-500  dark:text-slate-100" />
                            <p className="mt-2 font-light text-gray-500 dark:text-slate-100">Ordenes de compra</p>
                        </div>
                        
                    </div>
                </div>
                <table className="m-4 mt-10 table overflow-y-scroll">
                    <thead>
                        <tr className='table-row w-full'>
                            <th className="px-6 table-cell bg-gray-100 dark:bg-slate-600 dark:text-slate-100 rounded-tl-lg">ID</th>
                            <th className="px-6 table-cell bg-gray-100 dark:bg-slate-600 dark:text-slate-100">Sitio</th>
                            <th className="px-6 table-cell bg-gray-100 dark:bg-slate-600 dark:text-slate-100">Tarea</th>
                            <th className="px-6 table-cell bg-gray-100 dark:bg-slate-600 dark:text-slate-100">Proveedor</th>
                            <th className="px-6 table-cell bg-gray-100 dark:bg-slate-600 dark:text-slate-100 rounded-tr-lg">Acciones</th>
                        </tr>
                    </thead>
                    <tbody className='table-row-group '>
                        {
                            invoices.length > 0 ?
                                invoices.map(invoice =>

                                    <tr key={invoice._id} className='table-row bg-slate-50 dark:bg-slate-700 dark:border-none border'>
                                        {
                                            invoice.obra === null ?
                                                <>
                                                    <td className='text-center dark:border-slate-600 dark:text-slate-100'>{invoice._id}</td>
                                                    <td className='text-left font-medium text-red-500 dark:border-slate-600 dark:text-slate-100' colSpan={3}>OC correspondiente a obra eliminada</td>
                                                    <td className='table-cell text-center '>
                                                        <button onClick={() => handleView(invoice.path)}><EyeIcon className='pt-2 w-8 h-8' /></button>
                                                        <button onClick={() => handleDownload(invoice.path, invoice._id)} className='rounded-md text-black'>
                                                            <DocumentDownloadIcon className="pt-2 w-8 h-8 text-black" />
                                                        </button>
                                                        <button onClick={() => handleDelete(invoice._id)} className='rounded-md '><TrashIcon className="w-8 h-8 text-black pt-2" /></button>
                                                    </td>
                                                </>
                                                :
                                                <>
                                                    <td className='table-cell text-center border dark:border-slate-600 dark:text-slate-100'>{invoice._id}</td>
                                                    <td className='table-cell text-center border dark:border-slate-600 dark:text-slate-100'>{obra?.SITIO.map(({ SITIO }) => SITIO).join(' - ')}</td>
                                                    <td className='table-cell text-center border dark:border-slate-600 dark:text-slate-100'>{obra?.TAREA}</td>
                                                    <td className='table-cell text-center border dark:border-slate-600 dark:text-slate-100'>{obra?.CONTRATISTA.name}</td>
                                                    <td className='table-cell text-center border dark:border-slate-600 dark:text-slate-100'>
                                                        <button onClick={() => handleView(invoice.path)}><EyeIcon className='pt-2 w-8 h-8' /></button>
                                                        <button onClick={() => handleDownload(invoice.path, invoice._id)} className='rounded-md text-black'>
                                                            <DocumentDownloadIcon className="pt-2 w-8 h-8 text-black dark:text-slate-100" />
                                                        </button>
                                                        <button onClick={() => handleDelete(invoice._id)} className='rounded-md '>
                                                            {
                                                                loading ?
                                                                    <svg className="animate-spin h-4 w-4 ml-2 -mt-5 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                    </svg>
                                                                    :
                                                                    <TrashIcon className="w-8 h-8 text-black pt-2 dark:text-slate-100" />
                                                            }

                                                        </button>
                                                    </td>
                                                </>
                                        }
                                    </tr>
                                )
                                :
                                <tr >
                                    <td colSpan={4} className='text-center p-12 dark:text-slate-100'>No hay ordenes disponibles</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default OCProveedores;