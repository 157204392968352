import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import LoadingSpinner from '../../../../../LoadingSpinner'

const PedidosObra = () => {

    const { tarea } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [pedidos, setPedidos] = useState([])
    const [loading, setLoading] = useState(false)

    const getPedidos = async () => {


        setLoading(true);
        try {
            const response = await axiosPrivate.get(`obra/${tarea}`)
            setPedidos(response.data.PEDIDO)
        } catch (error) {
            throw new Error(Error)
        }
        setLoading(false);
    }

    useEffect(() => {
        getPedidos()
    }, [])


    return (
        <>
                <p className='text-gray-500 dark:text-slate-100 font-light p-4'>Pedidos</p>
                {
                    loading ?
                        <div className='grid grid-cols-12 h-screen'>
                            <div className='col-start-7 self-center'>
                                <LoadingSpinner />
                            </div>
                        </div>
                        :
                        pedidos.length === 0 ?
                           
                                    <p className='text-center mt-64 text-2xl font-extralight text-gray-500 dark:text-slate-100'>No hay pedidos realizados para esta obra</p>
                           
                            :
                            <>
                                <div className='flex flex-col gap-2 p-4 pb-24'>

                                    {
                                        pedidos.map(pedido =>

                                            <div className='border overflow-scroll bg-white dark:bg-slate-700 dark:shadow-none dark:border-none shadow-xl rounded-xl mt-4'>

                                                <div className='m-4 flex flex-row justify-around'>
                                                    <div>
                                                        <p className='font-light text-sm dark:text-slate-100'><label htmlFor="solicitante">Estado</label></p>
                                                        <div className='flex flex-row gap-4'>
                                                            <p className='self-end mt-4 text-xs font-extralight dark:text-slate-100'>{pedido.estado}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='font-light text-sm'><label className='dark:text-slate-100' htmlFor="solicitante">Solicitante</label></p>
                                                        <div className='flex flex-row gap-4'>
                                                            <img id='solicitante' className="w-12 h-12 rounded-full mt-2" src={process.env.REACT_APP_DEFAULT_SERVER + pedido?.solicitante?.image?.path} alt="" />
                                                            <p className='self-center text-xs font-extralight dark:text-slate-100'>{pedido.solicitante.nombre + ' ' + pedido.solicitante.apellido}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='font-light text-sm dark:text-slate-100'><label htmlFor="id">ID</label></p>
                                                        <div className='flex flex-row gap-4'>
                                                            <p className='self-center mt-4 text-xs font-extralight dark:text-slate-100'>{pedido._id}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='font-light text-sm dark:text-slate-100'><label htmlFor="fecha">Fecha de solicitud</label></p>
                                                        <div className='flex flex-row gap-4'>
                                                            <p className='self-center mt-4 text-xs font-extralight dark:text-slate-100'>{new Date(pedido.createdAt).toLocaleDateString('es-AR', { timeZone: 'UTC' })}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='p-4'>
                                                    <table className='w-full'>
                                                        <thead>
                                                            <tr className='bg-slate-50 dark:bg-slate-800'>
                                                                <th className='border rounded-tl-xl dark:border-slate-600 dark:text-slate-100'>Material</th>
                                                                <th className='p-4 border dark:border-slate-600 dark:text-slate-100'>Cantidad</th>
                                                                <th className='p-4 border dark:border-slate-600 dark:text-slate-100'>Precio unitario</th>
                                                                <th className='border dark:border-slate-600 rounded-tr-xl dark:text-slate-100'>Total</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody className=''>
                                                            {
                                                                pedido.materiales.map(material =>
                                                                    <tr className='border border-gray-100 bg-sky-50 dark:border-slate-600 dark:bg-slate-800'>
                                                                        <td className='text-left p-2 pl-4 text-sm font-light dark:text-slate-100 dark:border-slate-600'>{material.name}</td>
                                                                        <td className='text-left p-2 pl-4 text-sm font-light dark:text-slate-100 dark:border-slate-600 '>{material.cantidad}</td>
                                                                        <td className='text-left p-2 pl-4 text-sm font-light dark:text-slate-100 dark:border-slate-600'>$ {material.precio.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                                        <td className='text-left p-2 pl-4 text-sm font-light dark:text-slate-100 dark:border-slate-600'>$ {(material.precio * material.cantidad).toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                            <tr className="mt-4">
                                                                <td className="mt-4 pl-2 p-1 text-gray-500 dark:text-slate-100 text-sm font-bold">Total: $ {pedido.materiales.reduce((acc, item) => (item.precio * item.cantidad) + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                            </tr>
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>


                                        )
                                    }

                                </div>
                                <div className='fixed bottom-0 p-8 w-full bg-gray-50 dark:bg-slate-700'>
                                    <p className='font-light text-sm dark:text-slate-100'>Total de todos los pedidos: $ {pedidos.reduce( (acc, item) => item.materiales.reduce((acc, item) => (item.precio * item.cantidad) + acc, 0) + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })} </p>
                                </div>
                            </>
                }
            </>
    )
}

export default PedidosObra