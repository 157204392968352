import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import useAuth from '../../hooks/useAuth'
import ViewPedidoConfirm from './ViewPedidoConfirm.jsx';


const Confirmados = (props) => {

    const { auth } = useAuth();
    const preparing = true;



    return (
        <>
                {

                    !props.loading &&
                    <table
                        className="w-full self-start overflow-y-scroll dark:bg-slate- text-sm text-left text-gray-500 mt-6 dark:text-slate-100">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:text-slate-100 dark:bg-slate-600 ">
                            <tr>
                                <th scope="col" className="py-3 px-6 ">
                                    ID
                                </th>
                                <th scope="col" className="py-3 px-6 ">
                                    CONTRATISTA
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Solicitante
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    SITIO
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    TAREA
                                </th>
                                {
                                    (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                    <th scope="col" className="py-3 px-6">
                                        PRECIO
                                    </th>
                                }
                                <th colSpan={3} scope="col" className="py-3 px-6">
                                    ESTADO
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (auth.roles.includes('admin') || auth.roles.includes('logistica')) ?
                                    props.pedidos.map(item =>
                                        item.estado !== 'A confirmar' ? null :
                                            <tr key={item._id} className="bg-gray-100 dark:bg-slate-700 dark:hover:bg-slate-600 hover:duration-300 hover:bg-gray-200 ">
                                                <td className="py-2 pl-4 whitespace-nowrap">{item._id}</td>
                                                <td className="py-2 whitespace-nowrap">{item.solicitante.contratista.name}</td>
                                                <td className="py-2">
                                                    <div className='flex flex-row gap-4'>
                                                        <img className="w-10 h-10 rounded-full" src={process.env.REACT_APP_DEFAULT_SERVER + item.solicitante.image.path} alt="" />
                                                        <p className='self-center'>{`${item.solicitante.nombre} ${item.solicitante.apellido}`}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2">{item.obra?.SITIO[0].SITIO}</td>
                                                <td className="py-2">{item.obra?.TAREA}</td>
                                                {
                                                    (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                    <td className="py-2 px-6">{`$ ${item.materiales.reduce((acc, mat) => mat.precio * mat.cantidad + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })}`}</td>
                                                }
                                                <td className={` px-6`}>
                                                    {
                                                        preparing &&
                                                        <span className="relative inline-flex">
                                                            <button disabled type="button" className="inline-flex items-center px-4 font-semibold leading-6 text-sm shadow rounded-md text-amber-600 bg-white dark:bg-slate-600 dark:text-amber-300 transition ease-in-out duration-150 cursor-default ring-1 ring-slate-900/10">
                                                                {item.estado}
                                                            </button>
                                                            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                <span className="absolute inline-flex h-full w-full rounded-full bg-amber-500 opacity-75"></span>
                                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-600"></span>
                                                            </span>
                                                        </span>
                                                    }
                                                </td>
                                                <td className="px-6"><ViewPedidoConfirm pedido={item} electricos={props.stock} refresh={props.refresh} /></td>
                                            </tr>
                                    )
                                    :
                                    props.pedidos.map(item =>
                                        item.estado !== 'A confirmar' ? null :

                                            <tr key={item._id} className="bg-gray-100 dark:bg-slate-700 dark:hover:bg-slate-600 hover:duration-300 hover:bg-gray-200 ">
                                                <td className="py-2 px-6 whitespace-nowrap">{item._id}</td>
                                                <td className="py-2 whitespace-nowrap">{item.solicitante.contratista.name}</td>

                                                <td className="py-2 px-6 ">
                                                    <div className='flex flex-row gap-4'>
                                                        <img className="w-10 h-10 rounded-full" src={process.env.REACT_APP_DEFAULT_SERVER + item.solicitante.image.path} alt="" />
                                                        <p className='self-center'>{`${item.solicitante.nombre} ${item.solicitante.apellido}`}</p>
                                                    </div>
                                                </td>
                                                <td className="py-2 px-6">{item?.obra.SITIO[0].SITIO}</td>
                                                <td className="py-2 px-6">{item?.obra.TAREA}</td>
                                                {
                                                    (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                    <td className="py-2 px-6">{`$ ${item.materiales.reduce((acc, mat) => mat.precio * mat.cantidad + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })}`}</td>
                                                }
                                                <td className={`px-6`}>
                                                    {
                                                        preparing &&
                                                        <span className="relative inline-flex">
                                                            <button disabled type="button" className="inline-flex items-center px-4 font-semibold leading-6 text-sm shadow rounded-md text-amber-600 bg-white transition ease-in-out duration-150 cursor-default ">
                                                                {item.estado}
                                                            </button>
                                                            <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                <span className="absolute inline-flex h-full w-full rounded-full bg-amber-500 opacity-75"></span>
                                                                <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-600"></span>
                                                            </span>
                                                        </span>
                                                    }
                                                </td>
                                                <td className="px-6"><ViewPedidoConfirm pedido={item} refresh={props.refresh} /></td>
                                            </tr>
                                    )
                            }
                        </tbody>
                    </table>
                }
        </>
    )
}

export default Confirmados