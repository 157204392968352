import React from 'react'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import LoadingSpinner from '../../../../../LoadingSpinner'
import AddGastoVario from './AddGastoVario';
import { AnimatePresence, motion } from 'framer-motion';
import AddAlert from '../../../../../AddAlert'
import Gasto from './Gasto';



const GastosVarios = (props) => {
    const { tarea } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [obra, setObra] = useState({})
    const [gastosVarios, setGastosVarios] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState({ state: false, data: '' });

    const getObra = async () => {
        setLoading(true);
        try {
            const response = await axiosPrivate.get(`obra/${tarea}`)
            setObra(response.data)
            setGastosVarios(response.data.GASTOS_VARIOS)
        } catch (error) {
            throw new Error(error)
        }
        setLoading(false);
    }

    useEffect(() => {
        getObra()
    }, [])

    return (
        <div className='m-12'>
            <div className='top-0 fixed'>
                {
                    <AnimatePresence>
                        {
                            success.state && (
                                <motion.div
                                    className='flex-initial w-full'
                                    exit={{ opacity: 0 }}
                                >
                                    <AddAlert setSuccess={setSuccess} success={success} />
                                </motion.div>
                            )}
                    </AnimatePresence>
                }

            </div>
            <div className='flex flex-row justify-between items-center'>
                <p className='dark:text-slate-100'>Gastos varios</p>
                <AddGastoVario 
                    obra={obra} 
                    refresh={(data) => setGastosVarios([...gastosVarios, data])} 
                    success={(e, data) => { setSuccess({ state: e, data: data }) }}
                />
            </div>
            <div className='h-48 mt-16'>
                {loading ?

                    <div className='flex justify-center'>
                        <LoadingSpinner />
                    </div> :
                    <table className='table-auto w-full'>
                        <thead>
                            <tr className='border dark:border-slate-600'>
                                <th className='p-2 pl-4 text-left  dark:text-slate-100'>Descripción</th>
                                <th className='pl-4 text-left  dark:text-slate-100'>Monto</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gastosVarios.map((gasto) => (
                                <tr key={gasto._id}>
                                    <Gasto gasto={gasto} />
                                </tr>
                            ))}
                        </tbody>
                    </table>

                }
            </div>
        </div>
    )
}

export default GastosVarios