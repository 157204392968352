import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, EyeIcon } from '@heroicons/react/outline'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function ViewListos(props) {
    const [open, setOpen] = useState(false)
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const axiosPrivate = useAxiosPrivate();


    const handleEntregado = async () => {
        setLoading(true);

        try {
            const data = { estado: 'Entregado' }
            await axiosPrivate.put(`pedidos/${props.pedido._id}`, data)

        } catch (err) {
            const { error } = err?.response?.data;
            setErrMessage(error)
            throw new Error(err)
        }

        setOpen(false)
        setLoading(false)
        props.refresh();
    }

    return (
        <>
            <button onClick={() => setOpen(true)} ><EyeIcon className='w-7 h-7 hover:cursor-pointer hover:scale-105' /></button>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-20 h-screen overflow-scroll" onClose={() => {
                    setOpen(false)
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed grid h-screen self-center inset-0 overflow-y-scroll z-50">
                        <div className="flex self-center items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                {
                                    errMessage ?
                                        <Dialog.Panel className="p-12 relative h-fit max-h-screen transform rounded-lg bg-red-500 shadow-xl transition-all  sm:w-full sm:max-w-sm ">
                                            <Dialog.Title as='h3'>
                                                <p className='text-white font-lg'>{errMessage}</p>
                                            </Dialog.Title>
                                        </Dialog.Panel>


                                        :
                                        <Dialog.Panel className="relative h-fit max-h-screen transform overflow-y-scroll rounded-lg bg-white dark:bg-slate-700 px-4 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                            {
                                                <div>
                                                    <div className="border bg-gray-100 dark:bg-slate-600 dark:border-none p-2 rounded-b-lg text-center sm:mt-5">
                                                        <Dialog.Title as="h3" >
                                                            <div className="text-lg font-light text-gray-900 dark:text-slate-100">
                                                                Pedido ID: {props.pedido._id}
                                                            </div>

                                                        </Dialog.Title>
                                                    </div>
                                                    <div className="mt-6 sm:mt-5">
                                                        <Dialog.Description>
                                                            <div className='flex flex-col gap-2'>
                                                                <div className="text-sm font-light flex justify-between">
                                                                    <div className='bg-gray-100 text-green-600 p-2 rounded-lg dark:bg-slate-600 dark:text-green-300'>{props.pedido.estado}</div>
                                                                    <div className='bg-gray-100 text-gray-900 p-2 rounded-lg dark:bg-slate-600 dark:text-slate-100'>{props.pedido.obra.SITIO[0].SITIO}</div>
                                                                    <div className='bg-gray-100 text-gray-900 p-2 rounded-lg dark:bg-slate-600 dark:text-slate-100'>{props.pedido.obra.TAREA}</div>
                                                                </div>
                                                                <div>
                                                                    <div className=' text-gray-900 text-xs  font-extralight dark:text-slate-100'>Solicitado por {props.pedido.solicitante.nombre + ' ' + props.pedido.solicitante.apellido}</div>
                                                                </div>
                                                            </div>
                                                            <table className='mt-2 border-collapse border-spacing-0 w-full'>
                                                                <thead className="">
                                                                    <tr className='border rounded-t-lg dark:border-slate-600'>
                                                                        <th className="p-2 text-gray-500 text-sm font-light dark:text-slate-100 dark:border-slate-600">Descripción</th>
                                                                        <th className="p-2 text-gray-500 text-sm font-light dark:text-slate-100 dark:border-slate-600">Cantidad</th>
                                                                        {
                                                                            (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                                            <th className="p-2 text-gray-500 text-sm font-light dark:text-slate-100 dark:border-slate-600">Precio</th>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        props.pedido.materiales.map(electrico =>
                                                                            <tr key={electrico._id} className='border dark:border-slate-600'>
                                                                                <td className="pl-2 p-1 text-gray-500 text-sm font-light dark:text-slate-100 dark:border-slate-600">{electrico.name}</td>
                                                                                <td className="pl-2 p-1 text-gray-500 text-sm font-light dark:text-slate-100 dark:border-slate-600">{electrico.cantidad}</td>
                                                                                {
                                                                                    (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                                                    <th className="pl-2 pr-2 text-gray-500 text-sm font-light dark:text-slate-100 ">{` $ ${(electrico.precio * electrico.cantidad).toLocaleString('es-AR', { minimumFractionDigits: 2 })} `}</th>
                                                                                }
                                                                            </tr>

                                                                        )
                                                                    }
                                                                    {
                                                                        (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                                        <tr className="border dark:border-slate-600">
                                                                            <td className="pl-2 p-1 text-gray-500 text-sm font-bold dark:text-slate-100">Total: $ {props.pedido.materiales.reduce((acc, item) => (item.precio * item.cantidad) + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })}</td>
                                                                        </tr>
                                                                    }
                                                                </tbody>

                                                            </table>
                                                            {
                                                                (auth.roles.includes('admin') || auth.roles.includes('logistica')) &&
                                                                <div className='mt-6'>
                                                                    <button className="w-1/5 rounded-md bg-green-600 text-white p-2 text-sm flex gap-2 hover:bg-green-500 font-extralight hover:duration-200"
                                                                        disabled={loading}
                                                                        onClick={() => handleEntregado()}
                                                                    >
                                                                        {
                                                                            loading ?
                                                                                <div className="w-full text-center" role="status">
                                                                                    <svg className="inline w-4 h-4 text-green-400 animate-spin  fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                                    </svg>
                                                                                    <span className="sr-only">Loading...</span>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    Entregado <CheckIcon className="h-5 w-5" />
                                                                                </>

                                                                        }
                                                                    </button>
                                                                    <p className="text-gray-500 text-xs font-extralight mt-2 dark:text-slate-100">Se enviará un mail al solicitante del pedido. Podrá ver nuevamente el pedido en los detalles de la obra.</p>
                                                                </div>
                                                            }
                                                        </Dialog.Description>
                                                    </div>


                                                </div>
                                            }
                                        </Dialog.Panel>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default ViewListos;