import { useEffect, useState } from 'react'
import axios from 'axios'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useGlobalContext } from '../context/GlobalContext'

export default function useOcs(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const axiosPrivate = useAxiosPrivate();
  const { ocs, setOcs } = useGlobalContext()
  

  useEffect(() => {
    setOcs([])
    pageNumber = 0
  }, [query])

  


  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel
    const queryObra = query.obra ? (query?.obra !== '' ? `&obra=${query.obra}` : '') : ''
    const queryFacturadoMax = query.facturadoMax ? (query?.facturadoMax !== '' ? `&facturadoMax=${query.facturadoMax}` : '') : ''
    const queryFacturadoMin = query.facturadoMin ? (query?.facturadoMin !== '' ? `&facturadoMin=${query.facturadoMin}` : '') : ''
    const queryNro = query.nro ? (query?.nro !== '' ? `&nro=${query.nro}` : '') : ''

    axiosPrivate.get(`/ocsQuery?skip=${pageNumber}${queryObra}${queryFacturadoMin}${queryFacturadoMax}${queryNro}`, {
      cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(res => {
            setOcs(prevOcs => {
              return [...new Set([...prevOcs, ...res.data.map(b => b)])]
                })
            setHasMore(res.data.length > 0)
            setLoading(false)
        }).catch(e => {
            if (axios.isCancel(e)) return
            setError(true)
    })
    return () => cancel()
  }, [query, pageNumber])

  return { loading, error, ocs,  hasMore, setOcs }
}