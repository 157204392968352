import React, { useCallback, useRef, useState } from 'react'
import { useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import NewObra from './NewObra'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import AddAlert from '../../AddAlert'
import useObras from '../../../hooks/useObras'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import DebouncedInput from '../../../hooks/useDebounce'
import { useGlobalContext } from '../../../context/GlobalContext'

const Obras = () => {
  const axiosPrivate = useAxiosPrivate()
  const { queryObras, setQueryObras, setSelectedsFromObrasQuery } = useGlobalContext()
  const [pageNumber, setPageNumber] = useState(0)
  const [success, setSuccess] = useState({ state: false, data: '' });
  const [proyectos, setProyectos] = useState([])
  const [contratistas, setContratistas] = useState([])
  const [clientes, setClientes] = useState([])

  
  const {
    obras,
    hasMore,
    loading,
    error,
    setObras
  } = useObras(queryObras, pageNumber)

  const getProyectos = async () => {
    try {
      const { data } = await axiosPrivate.get(`proyectos`)
      setProyectos(data)
    } catch (error) {
      throw new Error(error)
    }
  }

  const getClientes = async () => {
    try {
      const { data } = await axiosPrivate.get(`cliente`)
      setClientes(data)
    } catch (error) {
      throw new Error(error)
    }
  }

  const getContratistas = async () => {
    try {
      const { data } = await axiosPrivate.get(`contratista`)
      setContratistas(data)
    } catch (error) {
      throw new Error(error)
    }
  }



  let navigate = useNavigate();


  const handleSelect = (e) => {
    if (e === 'null') {
      setQueryObras({ ...queryObras, estado: '' })
      setPageNumber(0);
    } else {
      setQueryObras({ ...queryObras, estado: e })
      setPageNumber(0);
    }
    
  }

  const handleProyecto = (e) => {
    if (e === 'null') {
      setQueryObras({ ...queryObras, proyecto: '' })
      setPageNumber(0);
    } else {
      setQueryObras({ ...queryObras, proyecto: e })
      setPageNumber(0);
    }
  }

  const handleContratista = (e) => {
    if (e === 'null') {
      setQueryObras({ ...queryObras, ejecutor: '' })
      setPageNumber(0);
    } else {
      setQueryObras({ ...queryObras, ejecutor: e })
      setPageNumber(0);
    }
  }

  const handleCliente = (e) => {
    if (e === 'null') {
      setQueryObras({ ...queryObras, cliente: '' })
      setPageNumber(0);
    } else {
      setQueryObras({ ...queryObras, cliente: e })
      setPageNumber(0);
    }
  }

  const handleSitio = (e) => {
    if (e === 'null') {
      setQueryObras({ ...queryObras, sitio: '' })
      setPageNumber(0);
    } else {
      setQueryObras({ ...queryObras, sitio: e })
      setPageNumber(0);
    }
    
  }





  useEffect(() => {
    getProyectos()
    getContratistas()
    getClientes()
    setQueryObras({...queryObras, sitio: ''})
    setSelectedsFromObrasQuery([])
  }, [])

  const observer = useRef()
  const lastObraElementRef = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNumber(prevPageNumber => prevPageNumber + 10)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading, hasMore])


  return (
    <div className='pb-4 pt-2 px-6 dark:bg-slate-800 h-full'>
      <div className='rounded-lg'>
        <div className="flex flex-row m-4 gap-4">
          <FontAwesomeIcon className='self-start w-12 h-12 text-slate-700 dark:text-slate-100' icon={faPersonDigging} />
        </div>
        <div>
          {
            <AnimatePresence>
              {
                success.state && (
                  <motion.div
                    className='flex-initial w-full'
                    exit={{ opacity: 0 }}
                  >
                    <AddAlert setSuccess={setSuccess} success={success} />
                  </motion.div>
                )}
            </AnimatePresence>
          }

        </div>
        <div className='mx-2 mb-2 rounded-lg'>
          <div className="flex flex-row gap-12">
            <div>
              <NewObra
                success={(e, data) => { setSuccess({ state: e, data: data }) }}
                refresh={(data) => setObras([...obras, data])}
                clientes={clientes}
                proyectos={proyectos}
                contratistas={contratistas}
              />
            </div>
            <div className='relative gap-6 flex'>
              <DebouncedInput  placeholder="Search..." route={'celdas'} cleaned={() => setQueryObras({...queryObras, sitio: ''})} selected={(e) => handleSitio(e)}/>
              <div className='flex flex-col'>
                <label className='-mt-8 font-semibnold dark:text-slate-100' htmlFor="selectEstado">Estado</label>
                <select value={queryObras.estado} name='selectEstado' className='mt-2 font-light bg-gray-100 dark:text-slate-100 rounded-md h-10 pl-2 dark:bg-slate-700' onChange={e => handleSelect(e.target.value)}>
                  <option selected value="null">Todos</option>
                  <option value={'Sin estado'}>Sin estado</option>
                  <option value={'En curso'}>En curso</option>
                  <option value={'Finalizada'}>Finalizada</option>
                </select>
              </div>
              <div className='flex flex-col'>
                <label className='-mt-8 font-semibnold  dark:text-slate-100' htmlFor="selectEstado">Proyecto</label>
                <select
                  value={queryObras.proyecto}
                  name='selectEstado'
                  className='mt-2 font-light bg-gray-100 dark:text-slate-100 rounded-md h-10 pl-2 dark:bg-slate-700'
                  onChange={e => handleProyecto(e.target.value)}>
                  <option value="null">Todos</option>
                  {
                    proyectos.map(proyecto =>
                      <option value={proyecto._id}>{proyecto.name}</option>
                    )
                  }

                </select>
              </div>
              <div className='flex flex-col'>
                <label className='-mt-8 font-semibnold dark:text-slate-100' htmlFor="selectEjecutor">Ejecutor</label>
                <select
                  name='selectEjecutor'
                  className='mt-2 font-light dark:text-slate-100 rounded-md h-10 pl-2 bg-gray-100 dark:bg-slate-700'
                  value={queryObras.ejecutor}
                  onChange={e => handleContratista(e.target.value)}>
                  <option value="null">Todos</option>
                  {
                    contratistas.map(contratista =>
                      <option value={contratista._id}>{contratista.name}</option>
                    )
                  }

                </select>
              </div>
              <div className='flex flex-col'>
                <label className='-mt-8 font-semibnold dark:text-slate-100' htmlFor="selectEjecutor">Cliente</label>
                <select
                  name='selectEjecutor'
                  className='mt-2 font-light dark:text-slate-100 rounded-md h-10 pl-2 bg-gray-100 dark:bg-slate-700'
                  value={queryObras.cliente}
                  onChange={e => handleCliente(e.target.value)}>
                  
                  <option value="null">Todos</option>
                  {
                    clientes.map(cliente =>
                      <option value={cliente._id}>{cliente.nombre}</option>
                    )
                  }

                </select>
              </div>
            </div>
          </div>
          <table className='w-full overflow-scroll border-separate border-spacing-y-3 mt-4'>
            <thead>
              <tr className='bg-gray-100 dark:bg-slate-700 '>
                <th className=' text-left font-light pl-4 rounded-tl-lg  dark:text-white'>Cliente</th>
                <th className=' text-left font-light pl-4  dark:text-white'>Tarea</th>
                <th className=' text-left font-light dark:text-white'>Sitio</th>
                <th className=' text-left font-light dark:text-white'>Proyecto</th>
                <th className=' text-left font-light dark:text-white'>Ejecutor</th>
                <th className=' text-left font-light dark:text-white'>Estado</th>
                <th className=' text-left font-light dark:text-white'>Fecha inicio</th>
                <th className=' text-left font-light dark:text-white rounded-tr-lg'>Fecha fin</th>
              </tr>
            </thead>
            <tbody>
              {
                obras.map((obra, index) => {
                  if (obras.length === index + 1) {
                    return (
                      <tr onClick={() => navigate(`${obra.TAREA}`)}
                        className='bg-gray-100 hover:bg-gray-200 hover:duration-300 dark:bg-slate-700 h-16 p-12 shadow-md  dark:hover:bg-slate-600 hover:cursor-pointer'
                        ref={lastObraElementRef}
                        key={obra}>
                        <td className='pl-4 text-md rounded-l-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.CLIENTE?.nombre}</td>
                        <td className='pl-4 text-md  py-2 dark:text-slate-100 font-light text-left'>{obra.TAREA}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.SITIO.map(({ SITIO }) => SITIO).join('-')} </td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.PROYECTO?.name}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.CONTRATISTA.name}</td>
                        <td className='text-md  py-2 rounded-r-md dark:text-slate-100 font-light  text-left'>
                          {
                            obra?.ESTADO === 'Sin estado' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full bg-gray-500">
                            </span>
                          }
                          {
                            obra?.ESTADO === 'En curso' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full bg-yellow-500">
                            </span>
                          }
                          {
                            obra?.ESTADO === 'Finalizada' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full bg-green-500">
                            </span>
                          }
                          {obra.ESTADO}
                        </td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{new Date(obra.FECHA_INICIO).toLocaleDateString()}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{new Date(obra.FECHA_FIN).toLocaleDateString()}</td>
                      </tr>
                    )
                  } else {
                    return (
                      <tr
                        onClick={() => navigate(`${obra.TAREA}`)}
                        className='bg-gray-100 hover:bg-gray-200 hover:duration-300 dark:bg-slate-700 h-16 shadow-md p-12  dark:hover:bg-slate-600 hover:cursor-pointer'
                        key={obra}>
                        <td className='pl-4 text-md rounded-l-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.CLIENTE?.nombre}</td>
                        <td className='pl-4 text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra.TAREA}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.SITIO.map(({ SITIO }) => SITIO).join('-')} </td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.PROYECTO?.name}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{obra?.CONTRATISTA.name}</td>
                        <td className='text-md  py-2 rounded-r-md dark:text-slate-100 font-light  text-left'>
                          {
                            obra?.ESTADO === 'Sin estado' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full  bg-gray-500">
                            </span>
                          }
                          {
                            obra?.ESTADO === 'En curso' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full bg-yellow-500">
                            </span>
                          }
                          {
                            obra?.ESTADO === 'Finalizada' &&
                            <span
                              style={{ height: '10px', width: '10px', display: 'inline-block' }}
                              className="mr-2 rounded-full bg-green-500">
                            </span>
                          }
                          {obra.ESTADO}
                        </td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{new Date(obra.FECHA_INICIO).toLocaleDateString()}</td>
                        <td className='text-md  py-2 dark:text-slate-100 font-light  text-left'>{new Date(obra.FECHA_FIN).toLocaleDateString()}</td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
        </div>
        <div className='p-12 mb-12'>
          {
            loading ?
              <div className='text-center m-16' role="status">
                <svg className="inline mr-2 w-8 h-8 dark:text-slate-600 animate-spin dark:fill-slate-100" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
              :
              obras.length === 0 &&

              <div className='self-end text-center'>
                <p className='text-2xl font-light whitespace-nowrap dark:text-slate-100'>No hay obras cargadas</p>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Obras;



