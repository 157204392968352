import {
    CurrencyDollarIcon,
} from '@heroicons/react/outline'
import useAuth from '../../hooks/useAuth'
import { Link } from 'react-router-dom'


const actions = [
    // {
    //     title: 'Ver calendario semanal',
    //     description: 'Week phase de semanas y sitios',
    //     href: '#',
    //     icon: CalendarIcon,
    //     iconForeground: 'text-yellow-900',
    //     iconBackground: 'bg-yellow-100',
    //     authorization: ['admin', 'logistica', 'user']
    // },
    {
        title: 'Generador OC a Proveedores',
        description: 'Visualizador y generador de ordenes de compra a proveedores',
        href: '/oc',
        icon: CurrencyDollarIcon,
        iconForeground: 'text-green-900',
        iconBackground: 'bg-green-100',
        authorization: ['admin']
    },
    // {
    //     title: 'Reporte mensual',
    //     description: 'Reporte de facturación y gastos',
    //     href: '#',
    //     icon: DocumentReportIcon,
    //     iconForeground: 'text-rose-900',
    //     iconBackground: 'bg-rose-100',
    //     authorization: ['admin']
    // }
]


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
    const { auth } = useAuth();
    return (
        <div className='px-12 sm:p-6 p-12 sm:h-100 sm:w-100 flex flex-col gap-12 h-screen'>
            <div className="rounded-lg  overflow-hidden shadow divide-y divide-gray-200 sm:divide-y sm:divide-gray-200 sm:grid sm:grid-cols-1 sm:mt-12">
                {actions.map((action, actionIdx) => (
                    action.authorization.includes(auth.roles[0]) &&
                    <div
                        key={action.title}
                        className={classNames(
                            actionIdx === 0 ? 'hover:bg-gray-100 dark:hover:bg-slate-600 dark:hover:text-slate-100 hover:duration-300 rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                            actionIdx === 1 ? 'hover:bg-gray-100 dark:hover:bg-slate-600 dark:hover:text-slate-100 hover:duration-300 sm:rounded-tr-lg' : '',
                            actionIdx === actions.length - 2 ? 'hover:bg-gray-100 dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:duration-300 sm:rounded-bl-lg' : '',
                            actionIdx === actions.length - 1 ? ' hover:bg-gray-100 dark:hover:text-slate-100 hover:duration-300 rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                            'relative group hover:bg-gray-100 dark:hover:bg-slate-600 dark:bg-slate-700 p-6'
                        )}
                    >
                        <div>
                            <span
                                className={classNames(
                                    action.iconBackground,
                                    action.iconForeground,
                                    'rounded-lg inline-flex p-3'
                                )}
                            >
                                <action.icon className="h-6 w-6" aria-hidden="true" />
                            </span>
                        </div>
                        <div className="mt-8 ">
                            <h3 className="text-lg font-medium">
                                <Link to={action.href} className="dark:text-slate-100 focus:outline-none">
                                    {/* Extend touch target to entire panel */}
                                    <span className="absolute inset-0 dark:text-slate-100 dark:group-hover:text-slate-200" aria-hidden="true" />
                                    {action.title}
                                </Link>
                            </h3>
                            <p className="mt-2 text-sm dark:text-slate-100 font-extralight">
                                {action.description}
                            </p>
                        </div>
                        <span
                            className="pointer-events-none absolute top-6 right-6  dark:text-slate-100 dark:group-hover:text-slate-200"
                            aria-hidden="true"
                        >
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                        </span>
                    </div>
                )
                
                )}
            </div>

                                    
        </div>
    )
}
