import { useEffect, useState } from 'react'
import axios from 'axios'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useGlobalContext } from '../context/GlobalContext'

export default function useObras(query, pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const axiosPrivate = useAxiosPrivate();
  const { obras, setObras } = useGlobalContext()

  useEffect(() => {
    setObras([])
    pageNumber = 0
  }, [query])

  useEffect(() => {
    setLoading(true)
    setError(false)
    let cancel
    const queryEstado = query.estado ? (query?.estado !== '' ? `&estado=${query.estado}` : '') : ''
    const queryProyecto = query.proyecto ? (query?.proyecto !== '' ? `&proyecto=${query.proyecto}` : '') : ''
    const queryEjecutor = query.ejecutor ? (query?.ejecutor !== '' ? `&ejecutor=${query.ejecutor}` : '') : ''
    const queryCliente = query.cliente ? (query?.cliente !== '' ? `&cliente=${query.cliente}` : '') : ''
    const querySitio = query.sitio ? (query.sitio !== '' ? `&sitio=${query.sitio}` : '') : ''

    
    axiosPrivate.get(`/obrasQuery?skip=${pageNumber}${querySitio}${queryEstado}${queryProyecto}${queryEjecutor}${queryCliente}`, {
      cancelToken: new axios.CancelToken(c => cancel = c) })
        .then(res => {
        setObras(prevObras => {
        return [...new Set([...prevObras, ...res.data.map(b => b)])]
      })
      setHasMore(res.data.length > 0)
      setLoading(false)
    }).catch(e => {
      if (axios.isCancel(e)) return
      setError(true)
    })
    return () => cancel()
  }, [query, pageNumber])

  return { loading, error, obras,  hasMore, setObras }
}