import React, { useState } from 'react'
import { SearchIcon, UsersIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { AnimatePresence, motion } from 'framer-motion'
import NewPersonal from './NewPersonal'
import { useParams, useNavigate } from 'react-router-dom'
import AddAlert from '../../AddAlert'

const Personal = () => {

  const [personas, setPersonas] = useState([]);
  const [search, setSearch] = useState('')
  const [success, setSuccess] = useState({ state: false, data: '' });
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams()
  const navigate = useNavigate();


  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get(`users/contratista/${id}`)
      setPersonas(response.data)
    } catch (error) {
      throw new Error(`Error: ${error}`)
    }

  }

  const searcher = (e) => {
    setSearch(e.target.value);
  }

  const results = !search ? personas : personas.filter((persona) =>
    persona.nombre.toLowerCase().includes(search.toLowerCase())
  )

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <div className='grid grid-cols-12 grid-rows-6 gap-4 p-6'>
      <div className='col-start-1 col-end-13 row-start-1 row-end-7  rounded-lg grid grid-rows-6 grid-cols-12'>
        <div className="col-start-1 col-end-3 row-start-1 flex flex-row">
          <p className="m-4 text-xl text-gray-800 dark:text-slate-100 items-center self-start ">Personal</p>
        </div>
        <div className='col-start-6 col-end-13 row-start-1'>
          <AnimatePresence>
            {
              success.state && (
                <motion.div
                  className='flex-initial w-full'
                  exit={{ opacity: 0 }}
                >
                  <AddAlert setSuccess={setSuccess} success={success} />
                </motion.div>
              )}
          </AnimatePresence>

        </div>
        <div className='col-start-1 col-end-13 row-start-2 row-end-7 mx-2 mb-2 self-start rounded-lg mt-4'>
          <div className="col-start-1 col-end-3 mb-4 flex flex-row gap-12">
            <div>
              <NewPersonal
                success={(e, data) => { setSuccess({ state: e, data: data }) }}
                refresh={(data) => setPersonas([...personas, data])}
                contratista={id}
              />
            </div>
            <div>
              <SearchIcon className="absolute mt-2 ml-2 h-5 w-5 text-gray-400 dark:text-slate-100" />
              <input onChange={(e) => searcher(e)} type="text" placeholder='Busqueda...' className="py-2 pl-10 outline-none dark:text-slate-100 bg-gray-200 dark:bg-slate-700 text-light rounded-md" />
            </div>
          </div>
            <table className='w-full overflow-scroll border-collapse shadow-md '>
              <thead>
                <tr className='bg-slate-600 dark:bg-slate-700'>
                  <th className='text-left text-md pl-4 rounded-tl-lg  text-white  '>Nombre</th>
                  <th className='text-left text-md   text-white '>DNI</th>
                  <th className='text-left text-md  text-white '>Fecha de nacimiento</th>
                  <th className='text-left text-md  text-white '>Contratista</th>
                  <th className='text-left text-md rounded-tr-lg  text-white '>Roles</th>
                </tr>
              </thead>
              <tbody>
                {

                  results && results.map(persona =>
                    <tr key={persona._id} onClick={() => navigate(`/recursos/${id}/${persona.username}`)} className='border border-gray-300 dark:bg-slate-700 dark:border-slate-600 dark:text-slate-100 hover:bg-gray-300 dark:hover:bg-slate-600 hover:cursor-pointer hover:duration-300'>
                      <td className='flex items-center pl-4'>
                        <img className='w-10 h-10 rounded-full' src={process.env.REACT_APP_DEFAULT_SERVER + persona.image.path} />
                        <div className='pl-3'>
                          <div>{persona.nombre+` `+persona.apellido}</div>
                        </div>
                      </td>
                      <td className='py-2 text-md text-black dark:text-slate-100 font-light text-left'>{persona.dni}</td>
                      <td className='py-2 text-md text-black dark:text-slate-100 font-light text-left'>{new Date(persona.fecha_nacimiento).toLocaleDateString('es-AR', {timeZone: 'UTC'})}</td>
                      <td className='py-2 text-md text-black dark:text-slate-100 font-light text-left'>{persona.contratista.name}</td>
                      <td className='py-2 text-md text-black dark:text-slate-100 font-light text-left'>{persona.roles.map(rol => rol.name).join('-')}</td>
                    </tr>
                  )
                }

              </tbody>


            </table>
          </div>
        </div>
      </div>
  )
}

export default Personal;



