import React, { useState } from 'react'
import { GoogleMap, useLoadScript, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import AsyncSelect from 'react-select/async'
import LoadingSpinner from '../LoadingSpinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate'


const Mapa = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAd15kyjZWqkLd17tHr4IJpoUr20Fxnzhg"
    })

    if (!isLoaded) return (
        <div className='grid grid-cols-12 grid-rows-3 h-screen'>
            <div className='col-start-6 col-end-13 row-start-4 self-center'>
                <LoadingSpinner />
            </div>
        </div>
    )
    return <Map />




    function Map() {
        const [activeMarker, setActiveMarker] = useState('');
        const [ celdas, setCeldas ] = useState([])
        const axiosPrivate = useAxiosPrivate();

        const getCeldas = async (inputText, callback) => {
            try {
                const response = await axiosPrivate.get(`celdas/${inputText.toUpperCase()}`);
                callback(response.data.map(i => ({ value: { SITIO: i.SITIO, NOMBRE_SITIO: i.NOMBRE_SITIO, position: { lat: i.LATITUD, lng: i.LONGITUD }, _id: i._id }, label: `${i.SITIO} - ${i.NOMBRE_SITIO}` })))
            } catch (error) {
                throw new Error(error);
            }
        }

        const handleActiveMarker = (marker) => {

            if (marker === activeMarker) {
                return;
            }
            setActiveMarker(marker);
        };

        const handleCeldaChange = (e) => {
            setCeldas(e)
        }



        const optionsCircle = {
            strokeColor: 'red',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'gray',
            fillOpacity: 0.35,
            clickable: false,
            draggable: false,
            path: celdas.map(({ value }) => value.position),
            editable: false,
            visible: true,
            radius: 250,
        }

        const customStyles = {
            control: (base, state) => ({
              ...base,
              height: '60px',
              'min-height': '34px',
              overflowY: 'scroll',
              marginTop: '16px',
              marginBottom: '16px',
              boxShadow:'12px solid black',
            }),
        };



        return (
            <>
                <div className='grid grid-cols-12'>
                    <div className='mt-4 row-start-1 col-start-2 col-end-12 z-40 flex flex-col w-full'>
                        <div className='sm:mt-12'>
                            <AsyncSelect
                                id='select'
                                isMulti
                                value={celdas}
                                onChange={(e) => handleCeldaChange(e)}
                                placeholder="Ingrese Celda..."
                                loadOptions={getCeldas}
                                styles={customStyles}
                            />
                        </div>


                        <div className='row-start-1 mt-2 col-start-2 col-end-12 rounded-lg shadow-xl drop-shadow-2xl' >
                            <GoogleMap zoom={4} onClick={() => setActiveMarker(null)} center={{ lat: -34.0000000, lng: -64.0000000 }} mapContainerStyle={{ width: '100%', height: '75vh', borderRadius: '10px' }}  >
                                {
                                    celdas.map(({ value }) =>

                                        <>
                                            <div key={value._id}>

                                                <Marker
                                                    onClick={() => handleActiveMarker(value._id)}
                                                    position={value.position}
                                                    animation={window.google.maps.Animation.DROP}
                                                >
                                                    {activeMarker === value._id ? (
                                                        <InfoWindow
                                                            options={{disableAutoPan:'false', shouldFocus: 'false'}}
                                                            o
                                                            onCloseClick={() => setActiveMarker(null)}>
                                                            <div className='flex flex-col'>
                                                                <p className='font-bold'>{value.SITIO} - {value.NOMBRE_SITIO}</p>
                                                                <p>Latitud: {value.position.lat.toFixed(6)}  Longitud: {value.position.lng.toFixed(6)}</p>
                                                                <a className='font-bold' target="_blank" href={`https://www.google.es/maps?q=${value.position.lat},${value.position.lng}`}><p className='text-black'>Ir al sitio</p></a>
                                                            </div>
                                                        </InfoWindow>
                                                    ) : null}
                                                </Marker>
                                                <Circle options={optionsCircle} center={value.position} />
                                            </div>
                                        </>
                                    )
                                }
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Mapa;


