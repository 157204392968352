import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import LoadingSpinner from '../../../../LoadingSpinner'
import AddOC from './AddOC';
import { AnimatePresence, motion } from 'framer-motion'
import OC from './OC';
import AddAlert from '../../../../AddAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import DeleteOCS from './DeleteOCS';





const ViewOCS = (props) => {
    const { tarea } = useParams();
    const axiosPrivate = useAxiosPrivate();
    const [obra, setObra] = useState({})
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState({ state: false, data: '' });
    const [isRemovable, setIsRemovable] = useState(false)
    const [removables, setRemovables] = useState([])


    const getOcs = async () => {
        setLoading(true);
        try {
            const response = await axiosPrivate.get(`obra/${tarea}`)
            setObra(response.data)
        } catch (error) {
            throw new Error(Error)
        }
        setLoading(false);
    }

    const handleIsRemovable = () => {
        setIsRemovable(!isRemovable)
        setRemovables([])
    }

    useEffect(() => {
        getOcs()
    }, [])
    return (
        <>
            <div>
                <AnimatePresence>
                    {
                        success.state && (
                            <motion.div
                                className='flex-initial w-full'
                                exit={{ opacity: 0 }}
                            >
                                <AddAlert setSuccess={setSuccess} success={success} />
                            </motion.div>
                        )}
                </AnimatePresence>
            </div>
            <div className={`flex justify-end px-4 items-center`}>
                <AddOC
                    obra={obra}
                    success={(e, data) => { setSuccess({ state: e, data: data }) }}
                    refresh={(data) => {
                        obra.ORDENES_COMPRA = [...obra.ORDENES_COMPRA, data]
                        setObra(obra)
                    }} />

                {
                    isRemovable && (
                        <div className='flex flex-col '>
                            <div className={`flex flex-row gap-4 ${isRemovable ? 'pl-12 pr-4' : ''}`}>
                                <button
                                    onClick={() => {
                                        setIsRemovable(false)
                                        setRemovables([])
                                    }
                                    }
                                    className="p-4 px-6 transition-all ease-in-out bg-yellow-600 hover:scale-105 hover:bg-yellow-500 hover:duration-300 text-white rounded-md"
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                                <DeleteOCS
                                    success={(e, data) => { setSuccess({ state: e, data: data }) }}
                                    refresh={(e) => setObra(e)}
                                    obra={obra._id}
                                    removables={removables} />
                            </div>
                            <p className='self-center fixed mt-16 font-semibold dark:text-slate-100'>{removables.length > 0 ? `${removables.length} OC a eliminar` : '0 a eliminar'}</p>
                        </div>
                    )
                }
                <button hidden={loading || obra?.ORDENES_COMPRA?.length == 0} disabled={isRemovable} onClick={handleIsRemovable} className={`disabled:bg-red-300 transition-all ease-in-out hover:scale-105 hover:bg-red-500 bg-red-600 text-white px-6 hover:duration-300 rounded-md p-4`}>Eliminar</button>

            </div>

            {
                loading ?
                    <div className='grid grid-cols-12 h-full bg-gray-50 dark:bg-slate-800'>
                        <div className='col-start-7 self-center'>
                            <LoadingSpinner />
                        </div>
                    </div>
                    :
                    (obra !== undefined) && obra?.ORDENES_COMPRA?.length === 0 ?
                        <div className='grid grid-cols-12 grid-rows-6'>
                            <div className='col-start-1 col-end-13 row-start-6 row-end-7 self-end text-center'>
                                <p className='text-2xl font-extralight text-gray-500 dark:text-slate-100'>No hay Ordenes de compra para esta obra</p>
                            </div>
                        </div>
                        :
                        <>
                            <div className='h-full'>
                                <div className='flex flex-wrap w-full gap-4 p-4'>
                                    {
                                        (obra !== undefined) && obra?.ORDENES_COMPRA?.map(oc =>

                                            <OC setRemovables={(e) => setRemovables(e)} removables={removables} addRemovable={(e) => setRemovables([...removables, e])} isRemovable={isRemovable} key={oc._id} oc={oc} />

                                        )
                                    }
                                </div>

                                <div className='fixed bottom-0 p-8 w-full bg-gray-50 dark:bg-slate-700'>
                                    <p className='font-semibold text-md dark:text-slate-100'>Total de todas las Ordenes: $ {obra?.ORDENES_COMPRA?.reduce((acc, oc) => oc.items.reduce((acc, item) => item.total + acc, 0) + acc, 0).toLocaleString('es-AR', { minimumFractionDigits: 2 })} </p>
                                </div>
                            </div>
                        </>
            }
        </>
    )
}

export default ViewOCS