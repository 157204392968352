import { SearchIcon } from '@heroicons/react/outline'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'


const MisObras = () => {
  const [ obras, setObras ] = useState()
  const [search, setSearch] = useState('')
  const getObras = async () => {

  }

  const searcher = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
      getObras()
  }, [])



  return (
    <div className='m-12 w-full bg-gray-800'>
        <div className='w-full p-4 bg-white grid grid-cols-12 gap-12 h-full'>
          <div className='row-start-1 col-start-1 col-end-2 text-md font-light self-start'>Obras</div>
          <div className='row-start-1 col-start-2 col-end-5 self-start'>
              <SearchIcon className="absolute mt-2 ml-2 h-5 w-5 text-gray-400" />
              <input onChange={(e) => searcher(e)} type="text" placeholder='Busqueda...' className="py-2 pl-10 outline-none bg-gray-200 text-light rounded-md" />
          </div>
          <div className='row-start-1 col-start-5 col-end-7 self-start'>
            <select className='w-full p-2 font-light bg-gray-200'>
             <option value="" disabled selected>Filtrar estado</option>
              <option>Sin estado</option>
              <option>En curso</option>
              <option>Finalizada</option>
            </select>
          </div>
        </div>
      </div>
  )
}

export default MisObras