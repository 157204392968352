import React from 'react'
import { CheckCircleIcon, XIcon } from '@heroicons/react/solid'
import { AnimatePresence, motion } from 'framer-motion'


const ElectricoAdded = (props) => {
   
    setTimeout(() => {
        props.setSuccess(!props.success.state)
    }, 2000)

    return (
        <>
            <AnimatePresence>
                {props.success.state && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ x: 0, opacity: 1}}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0}}
                    className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                            <p className="text-md font-medium text-green-800">{props.success.data}!</p>
                        </div>
                        <div className="ml-auto pl-3">
                        </div>
                    </div>
                </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}

export default ElectricoAdded