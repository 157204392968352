import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'

function NewPersonal(props) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [ contratista, setContratista ] = useState({})
    const { id } = useParams();
    const [ persona, setPersona ] = useState({
        nombre: '',
        apellido: '',
        user: '',
        fecha_nacimiento: '',
        dni: '',
        contratista: id,
    })

    const getContratistaByParams = async () => {
        try {
            const response = await axiosPrivate.get(`contratista/${id}`)
            setContratista(response.data)
        } catch (error) {
            throw new Error(`Error: ${error}`)
        }   
    }

    useEffect(() => {
        getContratistaByParams();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);
        try {
            const response = await axiosPrivate.post('register', persona)
            response.status === 201 &&
                setLoading(false);
                props.success(true, `Usuario ${response.data.username} creado`)
                props.refresh(response.data)
                setErr(null)
                setOpen(!open)
        } catch (error) {
            setErr(error.response.data.message)
            throw new Error(`Error: ${error}`)
        }

        
    }


    return (
        <>
            <button 
                onClick={() => setOpen(true)}
                className='mb-4 px-6 font-md bg-gray-700 hover:bg-gray-500 p-2 text-white text-sm rounded-md'>
                    Añadir Persona
            </button>
            
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className=" fixed z-10 self-center" onClose={() => {
                    setErr(false);
                    setOpen(false)
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-10 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="fixed h-fit min-h-max self-center dark:bg-slate-700 bg-white rounded-lg overflow-visible shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <Dialog.Title className="m-4 text-center font-medium dark:text-slate-100">Añadir Persona</Dialog.Title>
                                    <div>
                                        <form className="w-full mt-6" onSubmit={(e) => handleSubmit(e)}>
                                            <div className='ml-4 pr-40'>
                                                <div className='flex flex-col gap-2'>
                                                    {err &&
                                                        <div className="relative group mb-6">
                                                            <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='flex flex-col gap-2'>
                                                    <label className='text-left text-sm font-light dark:text-slate-100' htmlFor='nombre'>Nombre</label>
                                                    <input required onChange={(e) => {
                                                        setPersona({...persona, nombre: e.target.value })
                                                        setErr('')
                                                    }} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight w-full pl-2 px-28 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='text' name='nombre' id='nombre' />
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <label className='mt-4 text-left text-sm font-light dark:text-slate-100'>Apellido</label>
                                                    <input required onChange={(e) => {
                                                        setErr('')
                                                        setPersona({...persona, apellido: e.target.value })
                                                    }} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight pl-2 px-28 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='text' name='apellido' id='apellido' />
                                                </div>
                                                <div className='flex flex-col gap-1'>
                                                    <label className='mt-4 text-left text-sm font-light dark:text-slate-100'>Fecha de nacimiento</label>
                                                    <input value={persona.fecha_nacimiento} type="date" date required onChange={(e) => {
                                                        setErr('')
                                                        setPersona({...persona, fecha_nacimiento: e.target.value })
                                                    }} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' name='apellido' id='apellido' />
                                                </div>
                                                <div className='flex flex-col gap-2'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>DNI</label>
                                                    <input required onChange={(e) => {
                                                        setErr('')
                                                        setPersona({...persona, dni: e.target.value })
                                                    }} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='text' name='fechaNacimiento' id='fechaNacimiento' />
                                                </div>
                                                <div className='flex flex-col gap-2'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Email</label>
                                                    <input required onChange={(e) => {
                                                        setErr('')
                                                        setPersona({...persona, user: e.target.value })
                                                    }} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='email' name='dni' id='dni' />
                                                </div>
                                                <div className='flex flex-col gap-2'>
                                                    <label className='mt-4  text-left text-sm font-light dark:text-slate-100'>Contratista</label>
                                                    <input disabled value={contratista.name} className='dark:text-slate-100 dark:border-none dark:bg-slate-600 font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-blue-600 focus:ring-gray-blue focus:outline-none' type='text' name='dni' id='dni' />
                                                </div>                                                

                                            </div>
                                            <div className='flex justify-end mt-16'>
                                                <button className=' w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-600 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' type='submit'>
                                                    {
                                                        loading ?
                                                            <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            :
                                                            'Añadir'
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default NewPersonal;