import React from 'react'
import { useState } from 'react'
import { GoogleMap, useLoadScript, Marker, Circle, Polyline, InfoWindow } from '@react-google-maps/api'
import LoadingSpinner from '../../../LoadingSpinner'



const MapaObra = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })


  if (!isLoaded) return <LoadingSpinner />
  return <Map />




  function Map() {
    const [activeMarker, setActiveMarker] = useState(null);

    const handleOnLoad = (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      props.sitios.forEach(({ LATITUD, LONGITUD }) => bounds.extend({ lat: parseFloat(LATITUD), lng: parseFloat(LONGITUD) }));
      map.fitBounds(bounds);
    };

    const handleActiveMarker = (marker) => {

      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };

    const path = props.sitios.map(({ LATITUD, LONGITUD }) => ({ lat: LATITUD, lng: LONGITUD }))
    const optionsLine = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      zoom: 0,
      paths: props.sitios.map(({ LATITUD, LONGITUD }) => ({ lat: LATITUD, lng: LONGITUD })),
      zIndex: 1
    };
    const optionsCircle = {
      strokeColor: 'red',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: 'gray',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 250,
      zIndex: 1,
      zoom: 0
    }
    const OPTIONS = {
      minZoom: 4,
      maxZoom: 15,
    }
    return (
      <GoogleMap onClick={() => setActiveMarker(null)} onLoad={handleOnLoad} mapContainerStyle={{ width: '100%', height: '100%' }} options={OPTIONS} >
        <Polyline options={optionsLine} path={path} />
        {
          props.sitios.map(({ SITIO, NOMBRE_SITIO, LONGITUD, LATITUD, _id }) =>
            <div key={_id}>
              <Marker onClick={() => handleActiveMarker(_id)} position={{ lat: parseFloat(LATITUD), lng: parseFloat(LONGITUD) }}>
                {activeMarker === _id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                    <div>
                      <div className='flex flex-col'>
                        <p className='font-bold'>{SITIO} - {NOMBRE_SITIO}</p>
                        <p>Latitud: {LATITUD.toFixed(6)}  Longitud: {LONGITUD.toFixed(6)}</p>
                        <a className='font-bold' target="_blank" href={`https://www.google.es/maps?q=${LATITUD},${LONGITUD}`}><p className='text-black'>Ir al sitio</p></a>
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
              <Circle options={optionsCircle} center={{ lat: parseFloat(LATITUD), lng: parseFloat(LONGITUD) }} />
            </div>
          )
        }
      </GoogleMap>

    )
  }
}

export default MapaObra