import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import useRefreshToken from '../hooks/useRefreshToken';
import useAuth from '../hooks/useAuth';
import useLocalStorage from "../hooks/useLocalStorage";
import useLogout from "../hooks/useLogout";
import { useGlobalContext } from "../context/GlobalContext";


const PersistLogin = () => {
    const { refreshIsLoading, setRefreshIsLoading } = useGlobalContext();
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [persist] = useLocalStorage('persist', false);
    const signOut = useLogout();
    
    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                signOut();
            }
            finally {
                isMounted && setRefreshIsLoading(false);
            }
        }

        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setRefreshIsLoading(false);

        return () => isMounted = false;
    }, [])

    useEffect(() => {
        localStorage.setItem('persist', true);
    }, [refreshIsLoading])

    return (
        <>
            {
            !persist ? <Outlet /> : !refreshIsLoading && <Outlet />
            }
        </>
    )
}

export default PersistLogin;