import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import useAuth from '../../hooks/useAuth'
import ViewListos from './ViewListos';


const Listos = (props) => {

    const { auth } = useAuth();
    const listo = true;
    



    return (
        <>
            <AnimatePresence>

                {
                    !auth.roles.includes('user') ?
                        <ul className="flex flex-col gap-2 mt-6">
                            {props?.pedidos?.map(item =>
                                item.estado === 'Listo a entregar' ?

                                    <li key={item._id} className="flex flex-row justify-between border shadow-sm px-4 py-1 rounded-md dark:border-none dark:bg-slate-600 dark:hover:bg-slate-600 hover:bg-gray-200">
                                        <p className="font-light text-xs py-1">{item._id}</p>
                                            {
                                                listo &&
                                                <span className="relative inline-flex">
                                                    <button type="button" disabled className="inline-flex items-center px-4  font-semibold leading-6 text-sm shadow rounded-md text-green-600 dark:text-green-300 bg-white dark:bg-slate-500 transition ease-in-out duration-150 cursor-default ring-1 ring-slate-900/10" >
                                                        {item.estado}
                                                    </button>
                                                    <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                        <span className="absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                                                        <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                                                    </span>
                                                </span>
                                            }
                                            <ViewListos pedido={item} electricos={props.stock} refresh={props.refresh}/>
                                    </li>
                                    :
                                    null

                            )
                            }

                        </ul>
                        :
                        <ul className="flex flex-col gap-2 mt-6">
                            {
                                props?.pedidos.map(item =>
                                    item.estado === 'Listo a entregar' ?
                                        <li key={item._id} className="flex flex-row justify-between border shadow-sm px-4 py-1 rounded-md dark:border-none dark:hover:bg-slate-600 hover:bg-gray-200">
                                            <p className="font-light text-xs py-1">{item._id}</p>
                                                {
                                                    listo &&
                                                    <span className="relative inline-flex">
                                                        <button type="button" disabled className="inline-flex items-center px-4  font-semibold leading-6 text-sm shadow rounded-md text-green-600 bg-white dark:bg-slate-600 transition ease-in-out duration-150 cursor-default ring-1 ring-slate-900/10 ">
                                                            {item.estado}
                                                        </button>
                                                        <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                            <span className="absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600"></span>
                                                        </span>
                                                    </span>
                                                }
                                                <ViewListos pedido={item} electricos={props.stock} refresh={props.refresh}/>
                                        </li>
                                        :
                                        null
                                )
                            }
                        </ul>

                }



            </AnimatePresence>

        </>
    )
}

export default Listos;