import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';

function AddGastoVario(props) {
    const axiosPrivate = useAxiosPrivate();
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)



    const [gasto, setGasto] = useState({
        descripcion: '',
        monto: 0,
        obra: props.obra._id
    })

    const handleForm = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await axiosPrivate.post('gastos', gasto)
            setLoading(false)
            if (response.status === 201) {
                console.log(response.data)
                setOpen(false)
                props.refresh(response.data)
                props.success(true, `Gasto de $${response.data.monto} añadido!`)
                props.refresh(response.data)
                setErr(null)
            }
        } catch (error) {
            setLoading(false)
            props.error(true, error.response.data.message)
        }
    }

    const handleFormat = (e) => {
        document.getElementById('monto').value = parseFloat(e.target.value.toLocaleString('es-AR', { minimumFractionDigits: 2 }));
    }



    return (
        <>
            <button onClick={() => {
                setGasto({
                    descripcion: '',
                    monto: 0,
                    obra: props.obra._id,
                })
                setLoading(false);
                setErr('')
                setOpen(true)
            }
            } className='p-4 bg-blue-600 text-white rounded-md hover:bg-blue-500 hover:duration-300 transition-all ease-in-out hover:scale-105'>
                Añadir
            </button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="self-center" onClose={() => {
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="bg-slate-900 fixed inset-0 bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed z-50 inset-0">

                        <div className="flex items-end sm:items-center justify-center h-screen p-4 text-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="self-center dark:bg-slate-700 bg-gray-100 h-fit w-96 overflow-hidden  shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                    <div className='flex flex-row justify-center'>
                                        <Dialog.Title className="m-4 text-center font-medium dark:text-slate-100">Añadir gasto</Dialog.Title>
                                        <button onClick={() => setOpen(false)} className='p-2 px-4 right-0 fixed bg-red-600 hover:bg-red-500 hover:duration-200 text-white font-medium'>X</button>
                                    </div>
                                    <div className='h-full  pb-48'>
                                        <div className='flex flex-col gap-2'>
                                            {err &&
                                                <div className="relative group mb-6">
                                                    <p className={err ? "text-white relative px-7 py-4 bg-red-500 leading-none flex items-center divide-x divide-gray-600" : "offscreen"} aria-live="assertive">{err}</p>
                                                </div>
                                            }
                                        </div>
                                        <div className="flex flex-row gap-12">
                                            <div className='flex flex-col gap-2'>
                                                <form className="w-full mt-4" onSubmit={(e) => handleForm(e)}>
                                                    <div className='flex flex-col gap-2 p-4'>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Descripción</label>
                                                            <input
                                                                value={gasto.descripcion}
                                                                onChange={(e) => setGasto({ ...gasto, descripcion: e.target.value })}
                                                                className='mt-2 p-4 rounded-md dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight pl-2 border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' type='text' />
                                                        </div>
                                                        <div className='text-left flex flex-col'>
                                                            <label className='dark:text-slate-100 mt-4 text-left text-sm font-light'>Monto</label>
                                                            <div className='relative'>
                                                                <span className='absolute mt-6 pl-2 dark:text-slate-100 font-extralight'>$</span>
                                                                <input
                                                                    id='monto'
                                                                    pattern='(?=.{1,10}$)\d{1,3}(?:,\d{3})+|(?=.{1,8}$)\d+'
                                                                    value={(gasto.monto)}
                                                                    onChange={(e) => {
                                                                        handleFormat(e)
                                                                        setGasto({ ...gasto, monto: e.target.value }
                                                                        )
                                                                    }}
                                                                    className='pl-6 mt-2 p-4 rounded-md dark:bg-slate-600 dark:shadow-none dark:border-none dark:text-slate-100 shadow-md w-fit text-left font-extralight border border-gray-400 focus:duration-300 focus:border-black focus:ring-black focus:outline-none' step='0.01' type='number'
                                                                />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div style={{ width: '100%' }} className='bottom-0 fixed'>
                                                        <button disabled={gasto.monto <= 0 || gasto.descripcion === ''} type='submit' className='dark:border-none disabled:cursor-not-allowed disabled:bg-gray-300 w-full inline-flex justify-center border border-transparent shadow-sm px-4 py-4 bg-gray-600 text-base font-medium text-white hover:bg-gray-500 focus:outline-none  sm:col-start-2 sm:text-sm' >
                                                            {
                                                                loading ?
                                                                    <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                    </svg>
                                                                    :
                                                                    'Añadir'
                                                            }
                                                        </button>
                                                    </div>
                                                </form>


                                            </div>

                                        </div>


                                    </div>




                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog >
            </Transition.Root >
        </>
    )
}

export default AddGastoVario;