import axios from 'axios';
const BASE_URL = process.env.REACT_APP_DEFAULT_SERVER;

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

export const getObrasPage = async (pageParam = 1 , options = {}, cliente) => {
    const response = await axiosPrivate.get(`/obras/${cliente}?skip=${pageParam}?limit=2`, options)
    return response.data
}
