import React from 'react'
import { Link, useLocation } from 'react-router-dom'


const Breadcumb = () => {
    const location = useLocation();

    let currentLink = ''

    const crumbs = location.pathname
        .split('/')
        .filter(crumb => crumb !== '')
        .map(crumb => {
            currentLink += `/${crumb}`

            return (
                <li className='flex transition-all hover:ease-linear hover:duration-300 '>
                    <svg aria-hidden="true" className="w-6 h-6  dark:text-white self-center" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                    <Link to={currentLink} className='rounded-lg p-2 px-4 dark:text-white'>
                        <p className='font-medium text-sm'>{crumb}</p>
                    </Link>
                </li>    
            )
        })


return (

    <>
        {
            location.pathname === "/" ?
                null
                :
                <ol className="sm:hidden flex mt-4 ml-4">
                    <nav className="flex" aria-label="Breadcrumb">

                        <li className="inline-flex items-center transition-all dark:bg-gray-800 dark:hover:bg-gray-700 hover:ease-linear  hover:duration-300 ">
                            <Link to={'/'} className="inline-flex items-center text-sm font-medium rounded-xl p-2 dark:text-white">
                                <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                                <p className='pr-2'>Home</p>
                            </Link>
                        </li>
                        {crumbs}
                    </nav>
                </ol>
        }
    </>
)

}

export default Breadcumb