import { ArrowDownIcon } from '@heroicons/react/solid'
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { PieChart } from './PieChart';

const ViewGastos = () => {
  const { tarea } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const [pedidos, setPedidos] = useState([])
  const [OCproveedores, SetOCproveedores] = useState([])
  const [varios, setVarios] = useState([])
  const [loading, setLoading] = useState(false)

  const gastosPedidos = pedidos.reduce((acc, item) => item.materiales.reduce((acc, item) => (item.precio * item.cantidad) + acc, 0) + acc, 0)
  const gastosOCS = OCproveedores.reduce((acc, item) => item.items.reduce((acumulador, item) => item.total + acumulador, 0) + acc, 0)
  const gastosVarios = varios?.reduce((acc, item) => item.monto + acc, 0)

  const total = gastosOCS + gastosPedidos + gastosVarios
  const porcentajePedido = (gastosPedidos * 100) / total
  const porcentajeOC = (gastosOCS * 100) / total
  const porcentajeVarios = (gastosVarios * 100) / total

  const getObra = async () => {

    setLoading(true);
    try {
      const response = await axiosPrivate.get(`obra/${tarea}`)
      setPedidos(response.data.PEDIDO)
      SetOCproveedores(response.data.ORDENES_COMPRA_PROVEEDORES)
      setVarios(response.data.GASTOS_VARIOS)
    } catch (error) {
      throw new Error(error)
    }
    setLoading(false);
  }

  useEffect(() => {
    getObra()
  }, [])





  return (
        <div className='grid grid-cols-2 grid-rows-3 gap-4 p-10'>
          <div className='col-start-1 row-start-1 flex gap-4'>
            <></>
            <p className='font-medium text-2xl text-gray-800'>Gastos</p>
          </div>
          <Link to='pedidos'>
            <div className='h-full hover:scale-105 dark:bg-slate-700 hover:bg-gray-200 hover:-translate-x-10 transition-all duration-500 hover:duration-500 hover:cursor-pointer row-start-1 row-end-2 col-start-2 col-end-3 shadow-md rounded-md'>
              <div className='grid grid-cols-3 grid-rows-3 h-full'>
                <div className='col-start-1 col-end-2 row-start-1 self-center text-center'>
                  <p className='font-light text-2xl mt-2 dark:text-slate-100'>Pedidos</p>
                </div>
                <div className='col-start-1 col-end-4 row-start-2 text-center self-center'>
                  {
                    loading
                      ?
                      <div role="status">
                        <svg className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>

                      :

                      <p className='mt-2 text-2xl font-semibold dark:text-indigo-300 text-indigo-600'> $ {gastosPedidos.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</p>

                  }
                </div>
                <div className='w-fit col-start-3 col-end-4 row-start-2 self-center text-end mt-2  bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-2 rounded-full text-sm font-medium'>
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  {
                    loading
                      ?
                      <div role="status">
                        <svg className="inline mr-2 w-4 h-4 dark:fill-slate-100 text-gray-200 animate-spin dark:text-slate-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>

                      :
                      <>
                        {isNaN(porcentajePedido) ? 0 : porcentajePedido.toFixed(2) } %
                      </>
                  }
                </div>
              </div>

            </div>
          </Link>
          <Link to='ocs'>
            <div className=' h-full hover:scale-105 dark:bg-slate-700 hover:bg-gray-200 hover:-translate-x-10 hover:duration-500 transition-all duration-500 hover:cursor-pointer row-start-2 row-end-3 col-start-2 col-end-3 bg-white shadow-md rounded-md'>
              <div className='grid grid-cols-3 grid-rows-3 h-full'>
                <div className='col-start-1 col-end-2 row-start-1 self-center text-center'>
                  <p className='font-light text-2xl mt-2 dark:text-slate-100'>Proveedores</p>
                </div>
                <div className='col-start-1 col-end-4 row-start-2 text-center self-center'>
                  {
                    loading
                      ?
                      <div role="status">
                        <svg className="inline mr-2 w-4 h-4 dark:fill-slate-100 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>

                      :
                      <p className='mt-2 text-2xl font-semibold dark:text-indigo-300 text-indigo-600'>$ {gastosOCS.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</p>
                  }
                </div>
                <div className='w-fit col-start-3 col-end-4 row-start-2 self-center text-end mt-2  bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-2 rounded-full text-sm font-medium'>
                  <ArrowDownIcon
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                  {
                  loading
                    ?
                    <div role="status">
                      <svg className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>

                    :
                    <>
                      {isNaN(porcentajeOC) ? 0 : porcentajeOC.toFixed(2) } %
                    </>
                }
                </div>
              </div>
            </div>
          </Link>
          <Link to='varios'>
          <div className='h-full hover:scale-105 dark:bg-slate-700 hover:bg-gray-200 hover:-translate-x-10  hover:duration-500 transition-all duration-500 hover:cursor-pointer row-start-3 row-end-4 col-start-2 col-end-3 bg-white shadow-md rounded-md'>
            <div className='grid grid-cols-3 grid-rows-3 h-full'>
              <div className='col-start-1 col-end-2 row-start-1 self-center text-center'>
                <p className='font-light text-2xl mt-2 dark:text-slate-100'>Gastos varios</p>
              </div>
              <div className='col-start-1 col-end-4 row-start-2 text-center self-center'>
                {
                  loading
                    ?
                    <div role="status">
                      <svg className="inline mr-2 w-4 h-4 text-gray-200 dark:fill-slate-100 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>

                    :
                    <p className='mt-2 text-2xl font-semibold text-indigo-600 dark:text-indigo-300'> $ {gastosVarios.toLocaleString('es-AR', { minimumFractionDigits: 2 })}</p>

                }
              </div>
              <div className='w-fit col-start-3 col-end-4 row-start-2 self-center text-end mt-2  bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-2 rounded-full text-sm font-medium'>
                <ArrowDownIcon
                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  aria-hidden="true"
                />
                {
                  loading
                    ?
                    <div role="status">
                      <svg className="inline mr-2 w-4 h-4 dark:fill-slate-100 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>

                    :
                    <>
                      {isNaN(porcentajeVarios) ? 0 : porcentajeVarios.toFixed(2) } %
                    </>
                }
              </div>
            </div>
          </div>
          </Link>
          <div className='col-start-1 col-end-2 row-start-1 row-end-4 self-center p-16'>
            {
              loading
                ?
                <div className='self-center text-center' role="status">
                  <svg className="inline mr-2 w-4 h-4 dark:fill-slate-100 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>

                :
                <PieChart pedidos={porcentajePedido} varios={porcentajeVarios} ocs={porcentajeOC} />
            }
          </div>
          <div className='col-start-1 col-end-2 row-start-3 row-end-4 self-end p-4 bottom-0 fixed'>
            {
              loading
                ?
                <div className='self-center' role="status">
                  <svg className="inline mr-2 w-4 h-4 dark:fill-slate-100 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>

                :
                <div className='w-fit col-start-3 col-end-4 row-start-2 self-center  shadow-md text-end mt-2  bg-red-100 text-red-800 inline-flex items-baseline px-2.5 py-2 rounded-full text-sm font-medium'>
                  <>
                      <p className='text-2xl'>Total: ${total.toLocaleString('es-AR', {minimumFractionDigits:2})}</p>
                    </>
                </div>
            }
          </div>
      </div>
  )
}

export default ViewGastos