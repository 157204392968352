import React from 'react'
import { Link } from 'react-router-dom';
export const SitiosAsignados = (props) => {


  return (
   <>
                <div className='h-full p-6 row-start-2 overflow-y-scroll dark:overflow-y-scroll'>
                  {
                  !props.loading &&
                    <div>
                      {
                        props.obras.filter(obra => obra.ESTADO !== 'Finalizada').map(obra =>
                          <Link key={obra._id} to={`/pedidos/${obra.TAREA}`} className="flex flex-col rounded-md m-2 border dark:border-none dark:bg-slate-600 shadow-md dark:hover:text-slate-100 dark:hover:bg-slate-500 hover:duration-200 hover:bg-gray-200 hover:text-black hover:cursor-pointer ">
                            <div className={`flex flex-row border-l-4 ${obra.ESTADO.toLowerCase() === 'en curso' ? 'border-l-yellow-500' : 'border-l-gray-500'} rounded-md py-4 p-2`}>
                              <p className="w-2/5 font-light text-sm">{obra.TAREA}</p>
                              <p className="w-2/5 font-light  text-sm">{obra.CONTRATISTA.name}</p>
                              <p className="w-3/5 font-light  text-sm">{obra.SITIO.map(({SITIO}) => SITIO).join('-')}</p>
                              <p className="w-3/5 font-light  text-sm whitespace-nowrap">{obra?.PROYECTO?.name}</p>
                            </div>
                          </Link>
                        )
                      }
                    </div>
                  }
                </div>
   </>
  )
}

