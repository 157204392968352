import React, { useState } from 'react'
import { useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Confirm from './Confirm';

const Crear = () => {
  const axiosPrivate = useAxiosPrivate();
  const [electricos, setElectricos] = useState([])
  const { tarea } = useParams();
  const [obra, setObra] = useState({});
  const [loading, setLoading] = useState(true);


  const getElectricos = async () => {
    try {
      const response = await axiosPrivate.get('electricos');
      const electricos = response.data;
      electricos.forEach(electrico => electrico.cantidad = 0)
      setElectricos(electricos)
    } catch (error) {
      throw new Error(`Error: ${error}`)
    }
  }
 
  const getObra = async () => {
    try {
      const response = await axiosPrivate.get(`obra/${tarea}`);
      setObra(response.data)
    } catch (error) {
      throw new Error(`Error: ${error}`)
    }
  }

  useEffect(() => {
      getObra()
      getElectricos()
      setLoading(false);
  }, [])


  const counterChange = (e, index) => {

    const items = [...electricos];
    items[index].cantidad = parseFloat(e.target.value);
    setElectricos(items);
  }




  return (
    <div className="grid grid-cols-12 grid-rows-6 h-screen">
      <div className="border-gray-100 bg-gray-50 dark:bg-slate-700 dark:border-none row-start-1 grid row-end-2 ml-4 mt-4 rounded-md col-start-1 col-end-6 border shadow-md">
        {

          loading ?
            <>
              <div className="text-center self-center">
                <div role="status">
                  <svg className="inline mr-2 w-8 h-8 dark:text-slate-600 dark:fill-slate-100 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
            :
                obra?.TAREA && obra?.SITIO &&
                <div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="flex flex-row justify-between self-center mx-12 ">
                  <p className="font-bold  p-4 rounded-md bg-gray-100 dark:bg-slate-600 dark:text-slate-100 text-black">{obra?.TAREA}</p>
                  <p className="font-bold  p-4 rounded-md bg-gray-100 dark:bg-slate-600 dark:text-slate-100 text-black">{obra?.SITIO[0].SITIO}</p>
                  <p className="font-bold  p-4 rounded-md bg-gray-100 dark:bg-slate-600 dark:text-slate-100 text-black">{obra?.PROYECTO ? obra.PROYECTO.name : 'Sin Proyecto'}</p>
                </div>
              }
      </div>

      <ul

        className="border-gray-100  bg-gray-50 dark:bg-slate-700 dark:text-slate-100 dark:border-none row-start-2 row-end-7 my-4 ml-4 col-start-1 col-end-6 overflow-scroll border rounded-md shadow-md">
        {
          loading ?
            <>
              <div className="self-center text-center">
                <div role="status" className='self-center'>
                  <svg className="inline mr-2 w-8 h-8 dark:text-slate-600 dark:fill-slate-100 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </>
            :
                electricos.map((electrico, index) =>

                  <li
                    key={index} className="border dark:border-slate-600 flex flex-row justify-between">
                    <p className="ml-2 font-sm text-sm  ">{electrico.name}</p>
                    <input 
                    min={0} 
                      onKeyPress={(e) => e.code === "Minus" && e.preventDefault()} 
                      onPaste={(e) => e.preventDefault()} 
                      onChange={(e) => counterChange(e, index)} 
                      className="border w-1/4  text-center font-extralight text-xs bg-gray-50 dark:bg-slate-700 dark:text-slate-100 dark:border-none" type="number" value={electrico.cantidad} />
                  </li>
                )}
      </ul>

      <div className="border-gray-100 bg-gray-50 dark:bg-slate-700 dark:text-slate-100 dark:border-none m-4 row-start-1 row-end-7 col-start-6 col-end-13 border grid rounded-lg overflow-scroll shadow-md">
        {

          loading ?
            <div className="self-center text-center">
              <div role="status" className='self-center'>
                <svg className="inline mr-2 w-8 h-8 dark:text-slate-600 dark:fill-slate-100 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
            :
              <div>
                <p className="font-extralight text-xl text-center mt-2 dark:text-slate-100">Su pedido</p>
                {
                  electricos.filter(({ cantidad }) => cantidad > 0).length > 0 ?        
                      <table 
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                      className="w-11/12 ml-7">
                      <caption 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className="text-left m-4">
                          <Confirm 
                              electricos={electricos.filter(({ cantidad }) => cantidad > 0)}
                              obra={obra}
                            
                          />
                      </caption>
                      <thead>
                        <tr className='border rounded-lg'>
                          <th className="text-sm font-extralight">Descripción</th>
                          <th className="text-sn font-extralight">Cantidad</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          electricos.filter(({ cantidad }) => cantidad > 0).map(electrico =>
                            <tr key={electrico._id}
                              className="border rounded-xl hover:bg-gray-100 dark:hover:bg-slate-600">
                              <td className="text-sm font-extralight text-center p-2">{electrico.name}</td>
                              <td className="text-sm font-extralight text-center p-2">{electrico.cantidad}</td>
                            </tr>

                          )}
                      </tbody>
                    </table>


                    :
                    <p className="mt-48 self-center dark:text-slate-100 text-center font-extralight text-md ">No agregó nada a su pedido....</p>
                }
              </div>
        }
      </div>
    </div>
  )
}

export default Crear;