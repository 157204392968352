import React, { useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate';


const Upload = () => {
    const axiosPrivate = useAxiosPrivate();
    const [file, setFile] = useState(null)
    
    const handleFiles = (e) => {
     let files = e.target.files[0]
     setFile(files)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = document.querySelector("form");
        const formData = new FormData(form)
        formData.append('imagen', file)
        
        try {
            const response = 
                await axiosPrivate.post('upload/single', formData,
                    { 
                        headers: { "Content-Type": "multipart/form-data" } 
                    }
                )
        } catch (error) {
           throw new Error(`Error: ${error}`)
        }
    }
  return (
    <>
    <form action='uploadFile' encType='multipart/form-data' method='post' onSubmit={(e) => handleSubmit(e)}>
        <input onChange={(e) => handleFiles(e) }id="fileUpload" type="file" />
        <button className='border bg-blue-600 text-white p-4' type="submit">ENVIAR</button>
    </form>
    </>
  )
}

export default Upload