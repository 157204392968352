import { AnimatePresence, motion } from 'framer-motion'
import jwtDecode from 'jwt-decode';
import React from 'react'
import { useGlobalContext } from '../../context/GlobalContext';
import useAuth from '../../hooks/useAuth'
import ViewRejected from './ViewRejected';


const Rechazados = (props) => {

    const { auth } = useAuth();
    const rechazado = true;
    const { UserCache } = useGlobalContext()



    return (
        <>
                            {
                                (auth.roles.includes('admin') || auth.roles.includes('logistica')) ?
                                    <ul className="flex flex-col">
                                        {props.pedidos.map(item =>
                                            item.estado === 'Rechazado' ?

                                                <li key={item._id} className="bg-gray-100 dark:bg-slate-600 hover:bg-gray-200 flex justify-between rounded-md px-4">
                                                    <p className="py-2 text-xs dark:text-slate-100">{item._id}</p>
                                                    <div className="px-6 py-1">
                                                        {
                                                            rechazado &&
                                                            <span className="relative inline-flex">
                                                                <button type="button" className="inline-flex items-center px-4 dark:bg-slate-500 dark:text-red-300 font-semibold leading-6 text-sm shadow rounded-md text-red-600 bg-white  transition ease-in-out duration-150 cursor-not-allowed ring-1 ring-slate-900/10 " disabled="">
                                                                    {item.estado}
                                                                </button>
                                                                <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                    <span className="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                                                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                                                                </span>
                                                            </span>
                                                        }
                                                    </div>
                                                    <ViewRejected pedido={item} refresh={props.refresh} />
                                                </li>
                                                :
                                                null

                                        )
                                        }

                                    </ul>
                                    :
                                    <ul className="flex flex-col">
                                        { 
                                            props.pedidos.filter(({ obra }) => obra.CONTRATISTA.name === UserCache.contratista.name).map(item =>
                                                item.estado === 'Rechazado' ?
                                                    <li key={item._id} className="bg-gray-100 dark:bg-slate-600 hover:bg-gray-200 flex justify-between rounded-md px-4">
                                                        <p className="py-2 text-xs">{item._id}</p>
                                                        <div className="px-6 py-1">
                                                            {
                                                                rechazado &&
                                                                <span className="relative inline-flex">
                                                                    <button type="button" className="inline-flex items-center px-4  font-semibold leading-6 text-sm shadow rounded-md text-red-600 bg-white transition ease-in-out duration-150 cursor-not-allowed ring-1 ring-slate-900/10 " disabled="">
                                                                        {item.estado}
                                                                    </button>
                                                                    <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                                                                        <span className="absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                                                                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-600"></span>
                                                                    </span>
                                                                </span>
                                                            }
                                                        </div>
                                                        <ViewRejected pedido={item} refresh={props.refresh} />

                                                    </li>
                                                    :
                                                    null
                                            )
                                        }
                                    </ul>

                            }

        </>
    )
}

export default Rechazados;