import jwtDecode from 'jwt-decode';
import axios from '../app/api/axios';
import { useGlobalContext } from '../context/GlobalContext';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    const { setUserCache } = useGlobalContext()
    const refresh = async () => {
        const response = await axios.get('refresh', {
            withCredentials: true
        });
        const { UserInfo } = jwtDecode(response.data.accessToken);
        setUserCache(UserInfo)        
        setAuth(prev => {
            return {
                ...prev,
                roles: response.data.roles,
                accessToken: response.data.accessToken,
                username: response.data.username,
                UserInfo: UserInfo
            }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;