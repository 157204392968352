import Login from './components/Login';
import Layout from './components/Layout';
import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';
import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';
import { Routes, Route } from 'react-router-dom';
import Mapa from './components/Mapa/Mapa';
import Dashboard from './components/Dashboard/Dashboard';
import Recursos from './components/Recursos/Contratistas/Contratistas';
import UserView from './components/Recursos/UserView'
import Upload from './components/Upload';
import Crear from './components/Pedidos/Crear';
import Pedidos from './components/Pedidos/Pedidos';
import Invoices from './components/Invoice/Invoices';
import CreateInvoice from './components/Invoice/CreateInvoice';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Contratistas from './components/Recursos/Contratistas/Contratistas';
import Personal from './components/Recursos/Personal/Personal';
import VIewObra from './components/Obras/Obras/ViewObra/VIewObra';
import MisObras from './components/Obras/User/MisObras';
import PedidosObra from './components/Obras/Obras/ViewObra/Gastos/Pedidos/PedidosObra';
import ViewOCS from './components/Obras/Obras/ViewObra/ViewOCS/ViewOCS';
import OC from './components/Obras/Obras/ViewObra/ViewOCS/OC';
import ViewOC from './components/Obras/Obras/ViewObra/ViewOCS/ViewOC';
import ViewGastos from './components/Obras/Obras/ViewObra/Gastos/ViewGastos';
import ElectricosList from './components/Stock/Electrico/ElectricosList';
import Stock from './components/Stock/Stock';
import EditElectrico from './components/Stock/Electrico/EditElectrico';
import Obras from './components/Obras/Obras/Obras';
import OCProveedores from './components/Obras/Obras/ViewObra/Gastos/Proveedores/OcProveedores';
import FacturacionDashboard from './components/Facturacion/FacturacionDashboard';
import GastosVarios from './components/Obras/Obras/ViewObra/Gastos/Varios/GastosVarios';




const ROLES = {
  User: 'user',
  Logistica: 'logistica',
  Admin: 'admin'
}

function App() {


  return (


    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="login" element={<Login />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="/:page/:id/:token" element={<ResetPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />


        <Route element={<PersistLogin />}>
          <Route path="*" element={<Missing />} />

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Logistica]} />}>
            <Route path="/" index element={<Dashboard />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Logistica]} />}>
            <Route path="stock/" element={<Stock />} />
            <Route path="stock/electrico" element={<ElectricosList />} />
            <Route path="stock/electrico/:id" element={<EditElectrico />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Logistica]} />}>
            <Route path="mapa" element={<Mapa />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.User, ROLES.Logistica]} />}>
            <Route path="upload" element={<Upload />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Logistica]} />}>
            {/* <Route path="obras/" element={<Clientes />}/> */}
            <Route path="obras/" element={<Obras />}  />
            <Route path="obras/:tarea" element={<VIewObra />} />
            <Route path="obras/:tarea/gastos" element={<ViewGastos />} />
            <Route path="obras/:tarea/gastos/ocs" element={<OCProveedores />} />
            <Route path="obras/:tarea/gastos/pedidos" element={<PedidosObra />} />
            <Route path="obras/:tarea/gastos/varios" element={<GastosVarios />} />

          </Route>
            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="obras/:tarea/ocs" element={<ViewOCS />} />
            <Route path="obras/:tarea/ocs/:id" element={<ViewOC />} />
            <Route path="obras/:tarea/gastos/proveedores" element={<PedidosObra />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="facturacion" element={<FacturacionDashboard />} />
            <Route path="facturacion/:id" element={<ViewOC />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="recursos" element={<Contratistas />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Logistica, ROLES.User]} />}>
            <Route path="pedidos" element={<Pedidos />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Logistica, ROLES.User]} />}>
            <Route path="pedidos/:tarea" element={<Crear />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="recursos/" element={<Recursos />} />
            <Route path="recursos/:contratista" element={<Personal />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="recursos/" element={<Recursos />} />
            <Route path="recursos/:id/" element={<Personal />} />

          </Route>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Logistica, ROLES.User]} />}>
            <Route path="recursos/:id/:username" element={<UserView />} />
            <Route path="profile/:username" element={<UserView />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
            <Route path="/oc" element={<Invoices />} />
            <Route path="/oc/create" element={<CreateInvoice />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
            <Route path="misObras/" element={<MisObras />} />
          </Route>

        </Route>

      </Route>
    </Routes>
  );
}

export default App;
