import React from 'react'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ResetPassword() {
    const { id, token } = useParams();

    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [error, setError] = useState('')

    const handlePassword1 = (e) => {
        setPassword(e.target.value)
        setError('')
    }

    const handlePassword2 = (e) => {
        setPassword2(e.target.value)
        setError('')
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            const response = await axiosPrivate.post(`reset-password/${id}/${token}`, { password, password2 });

            setTimeout(() => {
                setMessage(response.data.message);
                setLoading(false);

            }, 1000);

        } catch (error) {
            if (error.response.status === 400) {
                setLoading(false)
                setError(error.response.data.message)
            } else {
                setMessage(error.response.data.message);
            }
            throw new Error(error.response);
        }
    }

    return (
        <>



            <section className="bg-gray-100">
                <div className="grid grid-rows-3 items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 w-1/3 sm:w-full">
                    <div className="flex justify-center items-center mb-6 text-2xl font-semibold text-gray-900 row-start-1">
                        <img className="h-1/2 w-1/2" src="/img/tera.png" alt="logo" />
                    </div>

                    {
                        message !== null ?

                            <div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 1 }}
                                className="z-10 relative w-full self-start p-6 bg-white flex flex-col gap-y-12 text-white rounded-lg shadow-xl md:mt-0 sm:max-w-md sm:p-8 row-start-2">
                                <div className='p-2  rounded-md bg-gray-600 shadow-md text-center'>
                                    <p className='font-extralight p-4'>{message}</p>
                                </div>
                                <Link to='/'>
                                    <div className='bg-black hover:bg-gray-600 hover:cursor-pointer shadow-md p-6 rounded-lg text-center'>
                                        <button className='text-white '>
                                            Ir al Login
                                        </button>
                                    </div>
                                </Link>
                            </div>
                            :
                            <>
                                <div className="z-10 w-full p-6 bg-white rounded-lg shadow md:mt-0 sm:max-w-md sm:p-8 row-start-2">
                                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                                        Reinicie su contraseña
                                    </h2>
                                    <p className="mb-1 text-sm font-sm leading-tight tracking-tight text-gray-500 ">
                                        Se actualizará su contraseña
                                    </p>
                                    {
                                        error ?
                                            <div className='mt-2 p-2 rounded-md text-center bg-red-500 text-white text-sm'>
                                                {error}
                                            </div>
                                            :
                                            null
                                    }
                                    <form onSubmit={(e) => handleSubmit(e)} className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#">
                                        <div>
                                            <label for="password" className="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
                                            <input onChange={(e) => handlePassword1(e)} type="password" name="password" id="password" className="outline-none bg-gray-50 border disabled:bg-gray-500 disabled:cursor-not-allowed border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 " placeholder="Ingrese su contraseña" required="" />
                                        </div>
                                        <div>
                                            <label for="password2" className="block mb-2 text-sm font-medium text-gray-900">Confirme contraseña</label>
                                            <input disabled={password.length === 0} onChange={(e) => handlePassword2(e)} type="password" name="password2" id="password2" className={`outline-none bg-gray-50 border disabled:bg-gray-200 disabled:cursor-not-allowed border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 ${(password === password2) && (password.length !== 0 && password2.length !== 0) ? 'border-2 border-green-300' : password.length === 0 ? '' : 'border-2 border-red-300'}`} placeholder="Confirme su contraseña" required="" />
                                        </div>
                                        <button disabled={(password === password2) ? false : true} type="submit" className={`w-full disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-600 bg-black text-white hover:bg-gray-800 hover focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center ${loading && 'bg-gray-100 hover:bg-gray-100'}`}>
                                            {
                                                loading ?
                                                    <div role="status">
                                                        <svg className="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <p>Reset password</p>
                                            }
                                        </button>
                                    </form>
                                </div>
                            </>
                    }
                </div>

            </section>

        </>
    )
}
