import React from 'react'
import { Link } from 'react-router-dom'

const OC = (props) => {
  
    const handleCheckBox = (e) => {
        if(e.target.checked) {
            props.addRemovable(props.oc._id)

        } else {
            const index = props.removables.filter(id => id !== props.oc._id)
            props.setRemovables(index)
        }
    }

    return (
        <>
            <div className='h-full w-60 hover:cursor-pointer rounded-md hover:bg-gray-200 hover:duration-300 hover:scale-105 dark:bg-slate-700 bg-gray-300 shadow-xl'>

                {
                    props.isRemovable &&
                    <>
                        <input onChange={e => handleCheckBox(e)} type="checkbox" className='h-6 w-6 absolute' />
                    </>
                }
                <Link className={`${props.isRemovable ? ' pointer-events-none' : ''}`} to={`${props.oc._id}`}>

                    <div className='flex flex-col p-3'>
                        <p className='font-medium self-center dark:text-slate-100'>{props.oc.nro}</p>
                        <p className='mt-4 text-sm dark:text-slate-100'>Cantidad de items: {props.oc.items.length}</p>
                        <div className='mt-4 flex gap-4 dark:text-slate-100'>
                            <p className='text-sm dark:text-slate-100'>Facturado:</p>
                            <div className="w-full h-fit bg-gray-400 dark:bg-slate-800">
                                <div className=" bg-blue-600 text-xs text-white text-center p-2 leading-none rounded-" style={{ width: `${props.oc.facturado * 100}%` }}> {props.oc.facturado * 100}%</div>
                            </div>
                        </div>
                        <p className='dark:text-slate-100 mt-4 row-start-4 self-end whitespace-nowrap w-full text-sm'>Total: $ {props.oc.items.reduce((acc, item) => item.total + acc, 0).toLocaleString('es-ar', { minimumFractionDigits: 2 })}</p>

                    </div>
                </Link>

            </div>
        </>
    )
}

export default OC;