import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/GlobalContext';

function Confirm(props) {
    
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [confirmedId, setConfirmedId] = useState('')
    const axiosPrivate = useAxiosPrivate();
    const { UserCache } = useGlobalContext();

   

    const handleConfirm = async () => {

        setLoading(true);

        const pedido = {
            obra: props.obra._id,
            estado: 'A confirmar',
            materiales: props.electricos,
            solicitante: UserCache.id
        }

        try {
            const response = await axiosPrivate.post('pedidos', pedido)
            setConfirmedId(response.data._id)
        } catch (error) {
            throw new Error(`Error: ${error}`)
        }

        setFinished(true)
        setLoading(false)
        props.refresh()
    }

    return (
        <>
            <button onClick={() => setOpen(true)} className="rounded-lg text-sm p-4 bg-gray-600 hover:bg-gray-500 text-white">Finalizar pedido</button>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-20 h-screen" onClose={() => {
                    finished ? setOpen(true) : setOpen(false)
                    setLoading(false)
                }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed grid h-screen self-center inset-0 overflow-y-scroll z-50">
                        <div className="flex self-center items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative h-fit max-h-screen transform overflow-y-auto rounded-lg bg-white dark:bg-slate-700 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                    {

                                        <div>
                                            <div className="mt-3 text-center sm:mt-5">
                                                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-90 dark:text-slate-100">
                                                    {
                                                        finished ? 'Orden completa' : 'Confirme su pedido'
                                                    }

                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <p className="text-sm font-extra-light text-gray-500 dark:text-slate-100">
                                                        {
                                                            finished ? `Pedido ID: ${confirmedId}` : 'Su pedido próximamente se confirmará'

                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-6 sm:mt-5">
                                                <Dialog.Description>
                                                    {
                                                        finished ?
                                                            <div className="flex justify-center">
                                                                <CheckIcon className="h-16 w-16 text-green-600 bg-green-100 rounded-full text-center" />
                                                            </div>
                                                            :
                                                            <>
                                                                <p className='font-light text-sm text-gray-500 dark:text-slate-100'>Materiales</p>
                                                                <table className='mt-2 border-collapse border-spacing-0 w-full'>
                                                                    <thead className="">
                                                                        <tr className='border rounded-t-lg dark:border-slate-600'>
                                                                            <th className="p-2 text-gray-500 dark:text-slate-100 text-sm font-light">Descripción</th>
                                                                            <th className="p-2 text-gray-500 dark:text-slate-100 first-letter:text-sm font-light">Cantidad</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            props.electricos.map(electrico =>
                                                                                <tr key={electrico._id} className='border dark:border-slate-600'>
                                                                                    <td className="p-1 text-gray-50 dark:text-slate-100 text-sm font-light">{electrico.name}</td>
                                                                                    <td className="p-1 text-gray-500 dark:text-slate-100 text-sm font-light">{electrico.cantidad}</td>
                                                                                </tr>


                                                                            )
                                                                        }

                                                                    </tbody>
                                                                </table>
                                                            </>
                                                    }
                                                </Dialog.Description>
                                            </div>

                                            <div className="mt-5 sm:mt-6 flex justify-center gap-12">
                                                {
                                                    loading ?

                                                        <div className="text-center self-center">
                                                            <div role="status">
                                                                <svg className="inline mr-2 w-8 h-8 text-gray-200 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                                </svg>
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>

                                                        :
                                                        <>
                                                            {
                                                                finished ?
                                                                    <Link to="/pedidos" className="w-full">
                                                                        <button
                                                                            type="button"
                                                                            className="w-full"
                                                                            onClick={() => setOpen(false)}
                                                                        >
                                                                            <div className="p-4 text-green-900 rounded-md bg-green-100 font-extralight hover:bg-green-200">
                                                                                Ver pedido
                                                                            </div>
                                                                        </button>
                                                                    </Link>
                                                                    :
                                                                    <>
                                                                        <button

                                                                            type="button"
                                                                            className="hover:scale-110 hover:duration-200"
                                                                            onClick={() => setOpen(false)}
                                                                        >
                                                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-600 hover:duration-200  hover:bg-red-500">
                                                                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                                            </div>
                                                                        </button>
                                                                        <button
                                                                            onClick={() => handleConfirm()}
                                                                            type="button"
                                                                            disabled={loading}
                                                                            className="hover:scale-110 hover:duration-200"

                                                                        >
                                                                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-600 hover:duration-200 hover:bg-green-500">
                                                                                <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                                                            </div>
                                                                        </button>
                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    }
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default Confirm;