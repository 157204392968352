import { AnimatePresence, motion } from 'framer-motion';
import React, { Suspense } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import DeleteElectrico from './DeleteElectrico';
import LoadingSpinner from '../../LoadingSpinner';
import ElectricoDeleted from './ElectricoDeleted';

const EditElectrico = () => {

    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [electrico, setElectrico] = useState({});
    const [err, setErr] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const [canEdit, setCanEdit] = useState(false);
    const [success, setSuccess] = useState({ state: false, data: '' });


    const getElectrico = async () => {
        try {
            const { data } = await axiosPrivate.get(`electricos/${id}`)
            setElectrico(data);
        } catch (error) {
            setErr(error.response.message);
            throw new Error(error.response.message)
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        setButtonLoader(true)
        try {
            const { data } = await axiosPrivate.put(`electricos/${id}`, electrico)
            setElectrico(data)
        } catch (error) {
            setErr(error.response.message)
        }
        setCanEdit(false)
        setButtonLoader(false)
    }

    useEffect(() => {
        getElectrico();
        setLoading(false);
    }, [])

    return (
        <>
            {
                loading ?
                    <div className='grid grid-rows-3 grid-cols-12 h-screen ' >
                        <div className='col-start-7 row-start-2 self-center'>
                            <svg className="self-center inline mr-2 w-8 h-8 text-gray-200 dark:text-slate-600 dark:fill-slate-100 animate-spin  fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348 C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <div className='h-screen w-full'>
                        <AnimatePresence>
                            {
                                success.state && (
                                    <motion.div
                                        className='flex-initial w-full'
                                        exit={{ opacity: 0 }}
                                    >
                                        <ElectricoDeleted setSuccess={setSuccess} success={success} />
                                    </motion.div>
                                )
                            }
                        </AnimatePresence>
                        <div className='flex flex-row'>
                            <div className='flex flex-col gap-16 m-12 w-1/2 h-full  rounded-lg shadow-md bg-white dark:bg-slate-700'>
                            {
                                 err &&
                                 <div className='w-full bg-red-500 text-white p-4' >
                                    <p className='text-md'>{err}</p>
                                 </div> 
                            }
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className='m-4 flex justify-between gap-16'>
                                        <p className='font-light text-xl drop-shadow-md dark:text-slate-100'>{electrico.name}</p>
                                        <div className='flex gap-4'>
                                            <button type='button' onClick={() => setCanEdit(!canEdit)} className='p-2 px-4 rounded-md shadow-md bg-yellow-500 hover:bg-yellow-400 hover:duration-200 text-white'>
                                                <FontAwesomeIcon icon={faEdit} className="w-6 h-6" />
                                            </button>
                                            <Suspense fallback={LoadingSpinner}>
                                                <DeleteElectrico  
                                                    success={(e, data) => {
                                                    setSuccess({ state: e, data: data })
                                                    }}  
                                                    error={(e) => setErr(e)}
                                                    refresh={() => getElectrico()} />
                                                
                                            </Suspense>
                                        </div>
                                    </div>
                                    <div className='m-4 flex flex-col relative'>

                                        <label className='dark:text-slate-100'>Precio</label>
                                        {
                                            canEdit ?
                                                <>
                                                    <span className='absolute dark:text-slate-100 text-md font-light mt-8 p-2'>$</span>
                                                    <input required onChange={(e) => setElectrico({ ...electrico, precio: e.target.value })} defaultValue={(electrico.precio)} type="number" className='dark:border-none mt-2 text-md pl-6 p-2 border shadow-sm font-light dark:bg-slate-600 dark:text-slate-100 bg-white w-fit pr-6 rounded-md' />
                                                </>
                                                :

                                                <p type="number" className='mt-2 text-md p-2 border shadow-sm font-light dark:border-none dark:bg-slate-600 dark:text-slate-100 bg-gray-100 w-fit pr-12 rounded-md'>$ {(electrico.precio)?.toFixed(2)}</p>

                                        }
                                    </div>
                                    <div className='ml-4 mt-6'>
                                        <label className='dark:text-slate-100'>En Stock</label>
                                        <p type="number" className='mt-2 text-md p-2 border shadow-sm font-light dark:bg-slate-600 dark:border-none dark:text-slate-100 bg-gray-100 w-fit pr-12 rounded-md'>{electrico.cantidad}</p>

                                    </div>
                                    <div className='ml-4 flex flex-col mt-6'>
                                        <label className='dark:text-slate-100'>Categoria</label>
                                        {
                                            canEdit ?
                                                <>
                                                    <select onChange={(e) => setElectrico({ ...electrico, category: e.target.value })} className='mt-2 text-md p-2 border shadow-sm font-light bg-white dark:border-none dark:bg-slate-600 dark:text-slate-100 w-fit pr-6 rounded-md'>
                                                        <option disabled selected>Seleccione una categoria</option>
                                                        <option required value="Cable">Cable</option>
                                                        <option required value="Miscelaneos">Miscelaneos</option>
                                                    </select>
                                                </>
                                                :
                                                <p className='mt-2 text-md p-2 border shadow-sm font-light dark:bg-slate-600 dark:border-none dark:text-slate-100 bg-gray-100 w-fit pr-12 rounded-md'>{electrico.category}</p>
                                        }
                                    </div>
                                    <div className='m-4'>
                                        {
                                            canEdit && <button type='submit' className='inline-flex justify-center p-4 mt-6 bg-gray-600 rounded-md text-white hover:bg-gray-500 hover:duration-300'>
                                                {
                                                    buttonLoader ?
                                                        <svg className="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        :
                                                        'Actualizar'
                                                }
                                            </button>
                                        }
                                    </div>
                                    <div className='bottom-0 fixed m-2'>
                                        <p className='font-light text-sm'>Última vez actualizado {new Date(electrico.updatedAt).toLocaleString()}</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

            }
        </>
    )
}

export default EditElectrico