import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import useLogout from '../../hooks/useLogout'
import useAuth from '../../hooks/useAuth';
import LoadingSpinner from '../LoadingSpinner';
import { useGlobalContext } from '../../context/GlobalContext';
import jwtDecode from 'jwt-decode';



const UserView = () => {
    const axiosPrivate = useAxiosPrivate();
    const { setUserCache, UserCache } = useGlobalContext()
    const [user, setUser] = useState()
    const { username } = useParams();
    const signOut = useLogout();
    const [file, setFile] = useState(null)
    const [disabled, setDisabled] = useState(true)
    const { auth, setAuth } = useAuth();
    const [loading, setLoading] = useState(false)

    const getUser = async () => {
        try {
            const response = await axiosPrivate.get(`user/${username}`)
            setUser(response.data)
        } catch (error) {
            throw new Error(`Error: ${error}`)
        }

    }
    const handleImageChange = async (e) => {
        e.preventDefault();
        const form = document.querySelector("form");
        const formData = new FormData(form)
        formData.append('imagen', file)


        setLoading(true)

        try {
            const response =
                await axiosPrivate.post(`uploads/profiles/${username}/profilePhoto`, formData,
                    {
                        headers: { "Content-Type": "multipart/form-data" }
                    }
                )
            if (response.status === 200) {
                try {
                    const updateProfileImage = await axiosPrivate.put(`user/${username}`, {
                        image: response.data.imagen
                    })
                    let userImageChange = user
                    let newImage = updateProfileImage.data.image
                    setUserCache({ ...UserCache, image: newImage })
                    setFile(null)
                    setDisabled(true)
                    setLoading(false)
                    setUser(userImageChange)
                    getUser()
                } catch (error) {
                    throw new Error(`Error: ${error}`)
                }
            }
        } catch (error) {
            throw new Error(`Error: ${error}`)
        }

    }

    useEffect(() => {
        getUser()
    }, [username])


    return (
        <>

            {user ?
                <div
                    className="sm:h-100 sm:w-100 grid grid-cols-12 sm:flex sm:flex-col grid-rows-4 sm:justify-start gap-x-4 sm:gap-x-0 sm:gap-y-6 p-12 sm:mb-4 justify-center items-center ">
                    <div
                        className='col-start-2 col-end-11 row-start-1' >
                        <div className='grid grid-cols-6 self-start'>
                            <img className="md:col-start-3 md:col-end-5 justify-self-center md:object-cover w-48 h-48 sm:w-36 sm:h-36 sm:col-start-1 sm:col-end-13 rounded-full" src={process.env.REACT_APP_DEFAULT_SERVER + user?.image?.path} alt="Profile" />
                            {
                                jwtDecode(auth.accessToken).UserInfo.username === username &&
                                <div className='sm:flex sm:mt-8 sm:col-start-2 sm:col-end-5  md:self-end'>
                                    <form id="form-submit" action="uploadFile" className='self-center' encType='multipart/form-data' method='post' onSubmit={(e) => handleImageChange(e)}>
                                        <input type="file"
                                            onChange={(e) => {
                                                setFile(e.target.files[0])
                                                setDisabled(false)
                                            }}
                                            className="text-transparent text-md font-md text-slate-500
                                                    file:mr-4 file:py-2 file:px-2
                                                    file:rounded-full file:border-0
                                                    file:bg-violet-50 file:text-violet-700
                                                    hover:file:bg-violet-100
                                                    "/>
                                    </form>
                                </div>
                            }
                        </div>

                    </div >
                    <div
                        className='mt-12 sm:mt-6 sm:w-2/3  col-start-2 col-end-5 row-start-2 bg-white dark:bg-slate-700 p-6 rounded-xl dark:border-none shadow-lg'>
                        <label htmlFor="email" className="block text-md font-md text-black dark:text-slate-100">
                            Email
                        </label>
                        <div className="mt-1">
                            <p
                                className="text-sm font-extralight w-full dark:text-slate-100 dark:bg-slate-700"
                            >
                                {user.username}
                            </p>
                        </div>
                    </div>
                    <div
                        className='mt-12 sm:mt-6 sm:w-2/3  col-start-5 col-end-8 row-start-2 bg-white dark:bg-slate-700 p-6 rounded-xl dark:border-none shadow-lg'>
                        <label htmlFor="name" className="block text-md font-md text-black dark:text-slate-100">
                            Nombre
                        </label>
                        <div className="mt-1">
                            <p
                                className="text-sm font-extralight w-full dark:text-slate-100 dark:bg-slate-700"
                            >
                                {`${user.nombre} ${user.apellido}`}
                            </p>
                        </div>
                    </div>
                    <div
                        className='mt-12 sm:mt-6 sm:w-2/3  col-start-8 col-end-11 row-start-2 bg-white dark:bg-slate-700 p-6 rounded-xl dark:border-none shadow-lg'>
                        <label htmlFor="name" className="block text-md font-md text-gray-700 dark:text-slate-100">
                            Fecha de nacimiento
                        </label>
                        <div className="mt-1">
                            <p
                                className="text-sm font-extralight w-full dark:text-slate-100 dark:bg-slate-700"
                            >
                                {new Date(user.fecha_nacimiento).toLocaleDateString('es-AR', { timeZone: 'UTC' })}
                            </p>
                        </div>
                    </div>
                    <div
                        className='sm:mt-0 sm:w-2/3  col-start-2 col-end-5 row-start-3 p-4 self-start bg-white sm:self-center rounded-xl dark:border-none dark:bg-slate-700 shadow-lg'>
                        <label htmlFor="Roles" className="block text-md font-md text-gray-700 dark:text-slate-100">
                            Roles
                        </label>
                        <div className='flex flex-row gap-2 justify-center bg-white dark:bg-slate-700'>
                            {
                                user.roles.map((role, index) =>
                                    <div key={index} className={`mt-1 border-gray-200 dark:border-none px-2 py-1 ${(role.name === 'admin') && `bg-green-50 text-green-900 text-sm rounded-xl`}
                                    ${(role.name === 'logistica') && `bg-red-100 text-sm text-red-900 rounded-xl`}
                                    ${(role.name === 'user') && `bg-yellow-100 text-sm text-yellow-900 rounded-xl`}
                                    `
                                    }>
                                        {role.name}
                                    </div>
                                )}
                        </div>
                    </div>
                    <div
                        className='sm:hidden col-start-5 col-end-8 bg-white sm:mt-0 sm:w-2/3 sm:self-center row-start-3 self-start p-5 rounded-xl dark:border-none dark:bg-slate-700 shadow-lg'>
                        <label htmlFor="DNI" className="block text-md font-md text-gray-700 dark:text-slate-100">
                            DNI
                        </label>
                        <div className="mt-1">
                            <p
                                className="text-sm font-extralight w-full dark:text-slate-100 dark:bg-slate-700"
                            >
                                {user.dni}
                            </p>
                        </div>
                    </div>
                    <div
                        className='sm:hidden col-start-8 col-end-11 bg-white row-start-3 sm:self-center sm:w-2/3 self-start p-5 rounded-xl dark:bg-slate-700  shadow-lg'>
                        <label htmlFor="name" className="block text-md font-md text-gray-700 dark:text-slate-100">
                            Contratista
                        </label>
                        <div className="mt-1">
                           <p
                                className="text-sm font-extralight w-full dark:text-slate-100 dark:bg-slate-700"
                                >
                               {user?.contratista?.name}
                            </p>
                        </div>
                    </div>
                    {
                        jwtDecode(auth.accessToken).UserInfo.username === username &&
                        <>
                            <div className="flex justify-between w-full row-start-4 col-start-2 col-end-11">
                                <div className='col-start-2 col-end-3 sm:self-start sm:ml-4  self-center'>
                                    <button
                                        onClick={() => signOut()}
                                        transition={{ type: 'just', duration: 0.5 }}
                                        className='hover:bg-red-400 text-md text-white shadow-sm shadow-red-500 bg-red-500 font-medium p-4 rounded-xl whitespace-nowrap'>
                                        Cerrar sesión
                                    </button>
                                </div>
                                {
                                    file &&
                                    <div className='col-start-10 col-end-10 sm:self-end sm:mr-4 self-center'>
                                        <button
                                            type='submit'
                                            disabled={loading}
                                            form="form-submit"
                                            className='disabled:bg-gray-400 hover:bg-slate-700 text-xs text-white bg-gray-800 font-medium p-4 px-6 sm:px-6 rounded-xl'>

                                            {
                                                loading ?

                                                    <div role="status">
                                                        <svg aria-hidden="true" className="mx-4 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                                        </svg>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                    :
                                                    <p>Guardar</p>

                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
                :
                <div className='grid grid-cols-3 grid-rows-3 h-screen'>
                    <div className='col-start-2 justify-self-center self-center row-start-2'>
                        <LoadingSpinner />
                    </div>
                </div>


            }
        </>
    )
}



export default UserView;